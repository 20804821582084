import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ValidationErrors } from "../api/apiError";
import { teamApi } from "../api/teamApi";
import type { RootState } from "../app/store";
import { Team } from "../models/team";
import { Competition } from "../models/competition";

interface TeamState {
  teams: Team[];
  currentTeamId: number | undefined;
  competitions: Competition[];
}

export const initialState: TeamState = {
  teams: [],
  currentTeamId: undefined,
  competitions: []

};

export const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    setTeams: (state, action: PayloadAction<Team[]>) => {
      state.teams = action.payload;
    },
    setCompetitions: (state, action: PayloadAction<Competition[]>) => {
      state.competitions = action.payload;
    },
    addCompetition: (state, action: PayloadAction<Competition>) => {
      state.competitions.push(action.payload);
    },
    setCurrentTeamId: (state, action: PayloadAction<number | undefined>) => {
      state.currentTeamId = action.payload;
    },
    DESTROY_TEAM_SLICE: (state) => {
      state.teams = [];
      state.currentTeamId = undefined;
    },
  },
});

export const { setTeams, setCompetitions, setCurrentTeamId, addCompetition, DESTROY_TEAM_SLICE } = teamSlice.actions;

export const getTeams = createAsyncThunk(
  "GET",
  async (data: {teamId: number| undefined; season: number | undefined}, { dispatch, rejectWithValue }) => {
    try {
      const {teamId, season} = data
      const res = await teamApi.getTeamsStats(season,teamId);
      dispatch(setTeams(res.data));
      return res.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);


export const getCompetitions = createAsyncThunk(
  "GET",
  async (season: number | undefined, { dispatch, rejectWithValue }) => {
    try {
      const competitions = await teamApi.getTeamCompetitions(season);
      dispatch(setCompetitions(competitions.data));
      return competitions.data;
    } catch (err) {
      const error: AxiosError<ValidationErrors> =
        err as AxiosError<ValidationErrors>;
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

export const selectTeam = (state: RootState) => state.teamSlice.teams[0];
export const selectTeamId = (state: RootState) => state.teamSlice.currentTeamId;
export const selectCompetitions = (state: RootState) => state.teamSlice.competitions;
export default teamSlice.reducer;
