import { useSelector } from "react-redux";
import { DARK_BLUE, LIGHT_BLUE, LIGHT_GREY, LIGHT_RED, RED } from "../../assets/colors";
import styled from "styled-components";
import { selectCurrentGame } from "../../slices/gamesSlice";
import { Game } from "../../models/game";
import CircleIcon from '@mui/icons-material/Circle';
import Shot from "../../models/shot";

const FieldShots = ({game }: { game: Game }) => {
const convertToShots = (
    rawShots: any[] | undefined,
    type: "l" | "r" | "h",
    target: boolean,
    color: string
  ): Shot[] => {
    return (rawShots || []) 
    .filter((shot) => shot[0] > 0 && shot[1] > 0) 
    .map((shot) => ({
      x: shot[0],
      y: shot[1],
      type,
      target,
      color,
    }));
  };
  // Collecting the shots and converting them
  const shots: Shot[] = game
    ? [
        ...convertToShots(game.shotRightOn, "r", true, "darkblue"),
        ...convertToShots(game.shotLeftOn, "l", true, "darkblue"),
        ...convertToShots(game.shotHeadOn, "h", true, "darkblue"),
        ...convertToShots(game.shotRightOff, "r", false, "lightblue"),
        ...convertToShots(game.shotLeftOff, "l", false, "lightblue"),
        ...convertToShots(game.shotHeadOff, "h", false, "lightblue"),
        ...convertToShots(game.shotRightOnOpp, "r", true, "red"),
        ...convertToShots(game.shotLeftOnOpp, "l", true, "red"),
        ...convertToShots(game.shotHeadOnOpp, "h", true, "red"),
        ...convertToShots(game.shotRightOffOpp, "r", false, "pink"),
        ...convertToShots(game.shotLeftOffOpp, "l", false, "pink"),
        ...convertToShots(game.shotHeadOffOpp, "h", false, "pink"),
      ]
    : [];
    console.log('SHOTS', shots)

    return game ? (
        <FieldContainer>
            <TitleField>
                <CircleIcon style={{ color: DARK_BLUE, fontSize: '20px', marginLeft: '10px', zIndex:2}} />
                <CircleIcon style={{ color: RED, fontSize: '20px', marginLeft: '-10px' }} />
                ON TARGET
                <CircleIcon style={{ color: LIGHT_BLUE, fontSize: '20px', marginLeft: '10px',zIndex:2 }} />
                <CircleIcon style={{ color: LIGHT_RED, fontSize: '20px', marginLeft: '-10px' }} />
                OFF TARGET
            </TitleField>
            <Field>
            {shots.map((shot, index) => {
                // Map x and y to field dimensions
                const fieldWidth = 100; // Adjust if the Field dimensions change
                const fieldHeight = 100; // Adjust if the Field dimensions change
                const adjustedX = shot.color.includes("darkblue") || shot.color.includes("lightblue") 
                ? 100 - shot.x 
                : shot.x;
                
                const xPos = (adjustedX / 100) * fieldWidth;
                const yPos = (shot.y / 60) * fieldHeight;
                return (
                <Circle
                    key={index}
                    x={xPos}
                    y={yPos}
                    color={shot.color}
                />
                );
            })}
            {game.logo && <HalfLogo src={game.logo} alt="Team Logo" side="left" />}
            {game.opponentLogo && <HalfLogo src={game.opponentLogo} alt="Opponent Logo" side="right" />}
            </Field>

            <BoxBlue></BoxBlue>
            <BoxRed >
            </BoxRed>

        </FieldContainer>
    ): (
        <></>
      );
    };

const FieldContainer = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1.2fr 1fr 1.2fr 0.8fr;
    grid-template-rows: 1fr 0.8fr 1.2fr 1.2fr 0.8fr; 
    font-family: Karla Regular;
    padding: 0 40px 40px 40px;
    border: 2px solid ${LIGHT_GREY};
    border-radius: 8px;
    margin: 10px 10px 10px 0px;
    background-color: white;
`;

const TitleField = styled.div`
  position: relative; /* Constrains child elements within the Field */
  grid-area: 1 / 1 / 1 / 6;
  font-family: Karla Regular;
  color: ${DARK_BLUE};
  text-align: center; /* Centers text horizontally */
  display: flex; /* Enables flexbox for vertical alignment */
  justify-content: center; /* Centers flex items horizontally */
  align-items: center; /* Centers flex items vertically */
`;

const Field = styled.div`
  position: relative; /* Constrains child elements within the Field */
  grid-area: 2 / 1 / 6 / 6;
  border: 2px solid ${DARK_BLUE};
  border-radius: 8px;
  width: 100%; /* Ensures Field is a proper container */
  height: 100%; /* Ensures Field is a proper container */
  overflow: hidden; /* Prevents circles from overflowing */
`;

const Circle = styled.div<{ x: number; y: number; color: string }>`
  position: absolute;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  width: 10px;
  height: 10px;
  left: ${({ x }) => x}%; // Position relative to Field
  top: ${({ y }) => y}%;  // Position relative to Field
  cursor: pointer;
  z-index: 3;
`;
const TeamLogo = styled.img`
  width: 50px;
  height:50px;
  height: auto;
  opacity: 0.5; /* Adjust transparency */
  display: block;
  margin: auto; /* Center the logo */
`;

const BoxBlue = styled.div`
    grid-area: 3 / 1 / 5 / 2;
    border-right: 2px solid ${DARK_BLUE};
    border-top: 2px solid ${DARK_BLUE};
    border-bottom: 2px solid ${DARK_BLUE};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Make sure the pseudo-element is positioned correctly */

    &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5; /* Apply opacity only to the background image */
    z-index: -1; /* Ensure the pseudo-element stays behind the content */
    }
`;

  const BoxRed = styled.div`
    grid-area: 3 / 5 / 5 / 6;
    border-left: 2px solid ${DARK_BLUE};
    border-top: 2px solid ${DARK_BLUE};
    border-bottom: 2px solid ${DARK_BLUE};
    border-right: 2px solid ${DARK_BLUE};
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative; /* Make sure the pseudo-element is positioned correctly */

    &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.5; /* Apply opacity only to the background image */
    z-index: -1; /* Ensure the pseudo-element stays behind the content */
    }
`;
const HalfLogo = styled.img<{ side: "left" | "right" }>`
  position: absolute;
  top: 50%;
  left: ${({ side }) => (side === "left" ? "40%" : "60%")};
  transform: translate(-50%, -50%);
  width: 60px;
  height: auto;
  opacity: 0.5;
  z-index: 2; /* Ensure it is above the boxes but below the shots */
`;


export default FieldShots;