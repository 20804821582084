import { Grid } from "@material-ui/core";
import styled from "styled-components";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY1,
  LIGHT_GREY,
  LIGHT_GREY2,
  RED,
  YELLOW,
  FOREST_GREEN,
  PURPLE,
  LIGHT_GREEN,
  LIGHT_PURPLE,
  WHITE,
} from "../../assets/colors";
import { Stat } from "../../models/player";
import PlayerProfileCard from "../../components/player-profile/player-profile-card/player-profile-card";
import PlayerStatGraph from "../../components/player-profile/player-stat-graph/player-stat-graph";
import TooltipCustom from "../../shared-components/tooltip";

const PlayerProfileOverview = ({ player, sizeFilterButtons }) => {
  const sortedStats = (type: string) => {
    let stats: Stat[] = [...player.stats];

    stats.sort((a, b) => b.performance - a.performance);

    return type === "success"
      ? stats.filter((stat) => !stat.isWarning)
      : stats.reverse().filter((stat) => stat.isWarning);
  };

  const getPlayerGraphs = (position: string) => {
    position = position.toLowerCase();

    switch (position) {
      case "forward":
        return (
          <>
            <PlayerStatGraph
              stats={player.performances.goals}
              title={"Scored Goals"}
              borderColor={DARK_BLUE}
              fillColor={LIGHT_GREY2}
            />
            <PlayerStatGraph
              stats={player.performances.assists}
              title={"Assists"}
              borderColor={FOREST_GREEN}
              fillColor={LIGHT_GREEN}
            />
            <PlayerStatGraph
              stats={player.performances.shots}
              title={"Shots"}
              borderColor={PURPLE}
              fillColor={LIGHT_PURPLE}
            />
          </>
        );
      case "goalkeeper":
        return (
          <>
            <PlayerStatGraph
              stats={player.performances.blocks}
              title={"Saves"}
              borderColor={DARK_BLUE}
              fillColor={LIGHT_GREY2}
            />
            <PlayerStatGraph
              stats={player.performances.facedShots}
              title={"Faced Shots"}
              borderColor={FOREST_GREEN}
              fillColor={LIGHT_GREEN}
            />
            <PlayerStatGraph
              stats={player.performances.successPasses} 
              title={"Pass Success"}
              borderColor={PURPLE}
              fillColor={LIGHT_PURPLE}
            />
          </>
        );
      default:
        return (
          <>
            <PlayerStatGraph
              stats={player.performances.successPasses}
              title={"Pass Success"}
              borderColor={DARK_BLUE}
              fillColor={LIGHT_GREY2}
            />
            <PlayerStatGraph
              stats={player.performances.ballLoss}
              title={"Ball Losses"}
              borderColor={FOREST_GREEN}
              fillColor={LIGHT_GREEN}
            />
            <PlayerStatGraph
              stats={player.performances.ballRecuperation}
              title={"Ball Recoveries"}
              borderColor={PURPLE}
              fillColor={LIGHT_PURPLE}
            />
          </>
        );
    }
  };

  return (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
      </FiltersWrapper>
      <PlayerOverallInfoWrapper>
        {player.position.toLowerCase() === "goalkeeper" ? (
          <>
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Minutes Played</PlayerStatHeader>
              <PlayerStatValue>{player.minutesPlayed}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Starters</PlayerStatHeader>
              <PlayerStatValue>{player.starters}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Conceded Goals</PlayerStatHeader>
              <PlayerStatValue>{player.concededGoals}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Saves</PlayerStatHeader>
              <PlayerStatValue>{player.shotBlocks}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Clean Sheets</PlayerStatHeader>
              <PlayerStatValue>{player.cleanSheets}</PlayerStatValue>
            </div>
          </>
        ) : (
          <>
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Minutes Played</PlayerStatHeader>
              <PlayerStatValue>{player.minutesPlayed}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Starters</PlayerStatHeader>
              <PlayerStatValue>{player.starters}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Goals</PlayerStatHeader>
              <PlayerStatValue>{player.goals}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>xG</PlayerStatHeader>
              <PlayerStatValue>{player.xg}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Assists</PlayerStatHeader>
              <PlayerStatValue>{player.assists}</PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Yellow Cards</PlayerStatHeader>
              <PlayerStatValue style={{ color: YELLOW }}>
                {player.yellowCards}
              </PlayerStatValue>
            </div>
            <VerticalLine />
            <div style={{ width: "100%", textAlign: "center" }}>
              <PlayerStatHeader>Red Cards</PlayerStatHeader>
              <PlayerStatValue style={{ color: RED }}>
                {player.redCards}
              </PlayerStatValue>
            </div>
          </>
        )}
      </PlayerOverallInfoWrapper>
      <HR></HR>
      <SectionHeader>Main Stats Trend</SectionHeader>
      {getPlayerGraphs(player.position)}
      <HR></HR>
      <SectionHeader>Performances evolution
        <TooltipCustom description={"Performance evolution tracks the biggest variations (good or bad) of a player over a selected time period."} />
      </SectionHeader>
      <HR></HR>
      <TwoColumns>
        <Column1>
      <SectionHeader>Best Performances</SectionHeader>
      {sortedStats("success").length > 0 ? (
        <>
          <Grid
            id="bestPerformanceGrid"
            container
            spacing={1}
            style={{
              height: sortedStats("success").length > 0 ? "140px" : "0px",
              overflowY: "hidden",
              margin: "1rem",
              backgroundColor: WHITE,
              border: `2px solid ${WHITE}` ,
              borderRadius: '5px' ,
            }}
          >
            {sortedStats("success").map((stat: Stat, index) => {
              return <PlayerProfileCard stat={stat} />;
            })}
          </Grid>
        </>
      ) : (
        <NoDataWrapper>No data</NoDataWrapper>
      )}
      </Column1>
      <Column2><SectionHeader>Warnings</SectionHeader>
      {sortedStats("warning").length > 0 ? (
        <>
          <Grid
            id="warningGrid"
            container
            spacing={1}
            style={{
              height: sortedStats("success").length > 0 ? "140px" : "0px",
              overflowY: "hidden",
              margin: "1rem",
              backgroundColor: WHITE,
              border: `2px solid ${WHITE}` ,
              borderRadius: '5px' ,
            }}
          >
            {sortedStats("warning").map((stat: Stat) => {
              return <PlayerProfileCard stat={stat} />;
            })}
          </Grid>
        </>
      ) : (
        <NoDataWrapper>No data </NoDataWrapper>
      )}</Column2>
      </TwoColumns> 
      <HR></HR>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
const TwoColumns = styled.div`
  width: 100%;
  display: grid;
  gap:10px;
  grid-template-columns: 1fr 1fr ;
  grid-template-areas: "Column1 Column2"; /* Fixed layout */
`;
const Column1 = styled.div`
  grid-area: Column1;
  width:99%;
`;
const Column2 = styled.div`
  grid-area: Column2;
  width:97%;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const PlayerOverallInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1rem 0;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  margin-top: 2rem;
`;
const PlayerStatHeader = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`;
const PlayerStatValue = styled.div`
  text-transform: uppercase;
  text-align: center;
  color: ${DARK_BLUE};
  font-size: 1.4rem;
`;
const VerticalLine = styled.div`
  height: 3rem;
  width: 2px;
  background-color: ${LIGHT_GREY};
`;
const SectionHeader = styled.div`
  padding-right: 10px;
  width: 100%;
  text-align: center; 
  font: 1rem 'Antique Olive Nord';
  color: ${DARK_BLUE};
  background-color: ${LIGHT_GREY1};
  text-transform: uppercase;
`;
const HR = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: -0.7rem;
  background-color: ${LIGHT_GREY};
`;

export default PlayerProfileOverview;
