import { useSelector } from "react-redux";
import styled from "styled-components";
import { Game } from "../../../models/game";
import { selectCurrentGame } from "../../../slices/gamesSlice";
import FourSectionPieChart from "../../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import GameStatSectionHeader from "../../../components/game-page/game-stat-section-header/game-stat-section-header";
import PlayerPositions from "../../../components/game-page/player-positions/player-positions";
import SectionField from "../../../components/game-page/section-field/section-field";
import GameSummary from "./game-summary";
import { selectTeam } from "../../../slices/teamSlice";
import { Team } from "../../../models/team";
import FieldShots from "../../shots/gameShotsMap"
import { DARK_BLUE, GREEN, LIGHT_BLUE, LIGHT_GREY, LIGHT_RED, RED } from "../../../assets/colors";

const GameOverview = () => {
  const game: Game | undefined = useSelector(selectCurrentGame);
  const team: Team = useSelector(selectTeam);
  // Helper function to convert raw shots to `Shot` type
  
  return game ? (
    <Wrapper>
      <TopRow>
        <GameSummary/>
        <PlayerPositions/>
      </TopRow>
      <GameStatSectionHeader title={"SHOTS & PASSES"}></GameStatSectionHeader>
      <Container>
        <FieldShots game={game} ></FieldShots>
        <PassOrientation>
          <FourSectionPieChart
            title={"Pass orientation (%)"}
            orientation={game.passOrientationRatio}
            successRatio={[]}
          ></FourSectionPieChart>
        </PassOrientation>
        <PassSuccess>
          <FourSectionPieChart
            title={"Pass success (%)"}
            orientation={game.passOrientationRatio}
            successRatio={game.passOrientationSuccessRatio}
          ></FourSectionPieChart>
        </PassSuccess>
      </Container>
      <GameStatSectionHeader title={"Transitions"} />
      <Fields>
      <FieldWrapper>
        <SectionField
          title={"Ball loss reactions"}
          data={game.ballLossReactions}
          color={"red_blue"}
        ></SectionField>
      </FieldWrapper>
      <FieldWrapper>
        <SectionField
          title={"Ball recoveries"}
          data={game.ballRecoveries}
          color={"blue"}
        ></SectionField>
      </FieldWrapper>
      </Fields>
      </Wrapper>
  ) : (
    <></>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height:100%;
`;

const FieldWrapper = styled.div`
  margin-bottom: 1rem;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Container  = styled.div` 
  display: grid; 
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: 1fr; 
  gap: 0px 0px; 
  background-color: white;
  border-radius: 8px;
  grid-template-areas: 
    "FieldContainer PassOrientation PassSuccess";
`;

const Fields = styled.div`
  width: 100%;
  display: grid;
  gap:10px;
  grid-template-columns: 1fr 1fr ;
`;

const PassOrientation = styled.div`
  grid-area: PassOrientation; 
  margin: 10px 10px 10px 10px;
`;

const PassSuccess = styled.div`
  grid-area: PassSuccess; 
  margin: 10px 10px 10px 10px;
`;

export default GameOverview;
