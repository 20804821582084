import { useEffect, useState } from 'react';
import styled from "styled-components";
import { DARK_BLUE, GREY, GREY3, WHITE } from "../../assets/colors";
import { PlayerGameInit } from "../../models/player"; 
import attIcon from "../../assets/images/Attacker_Icon.png"
import shirtBlueIcon from "../../assets/images/shirtBlueIcon.png"
import shirtBlueIconBorder from "../../assets/images/shirtBlueIconBorder.png"
import { sportsAiGlobalStyles } from "../../shared/globalStyle";
import SoccerField from '../../services/drawFieldLines';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { soccerFormations } from '../../models/constant/formations';
const formations: string[] = soccerFormations;

export const FieldPositions = ({ formation, setFormation, setCurrentPage, players, submitGame, dispatch, season, teamId }) => {
    const classes = sportsAiGlobalStyles();
    const [playerList, setPlayerList] = useState(players);
    const [assignedPlayers, setAssignedPlayers] = useState<{ [key: string]: PlayerGameInit | null }>({});
    // Group players by position
    const goalkeepers = playerList.filter(player => player.position === 'Goalkeeper');
    const defendersList = playerList.filter(player => player.position === 'Defender');
    const midfielders = playerList.filter(player => player.position === 'Midfielder');
    const forwardsList = playerList.filter(player => player.position === 'Forward');

    const formationParts = formation ? formation.split("-").map(Number) : "4-4-2".split("-").map(Number);
    const defenders = formationParts[0];
    const forwards = formationParts[formationParts.length - 1];
    const midfieldRows = formationParts.slice(1, -1);
    const [formErrorsSquad, setFormErrorsSquad] = useState({
        squadError: ""
    });
    const validatePlayersPosition = (assignedPlayers, defenders, mids) => {
    
        // Initialize counters for each position segment
        let defenderCount = defenders;
        let midfielderCount = mids;
    
        // Iterate through each player in assignedPlayers
        for (let key in assignedPlayers) {
            const player = assignedPlayers[key];
            const stadiumPosition = player.stadium_index_position;
    
            // Ensure player object has the required properties
            if (!stadiumPosition) {
                console.warn("Player missing stadium_index_position:", player);
                continue; // Skip this player if position data is missing
            }
    
            // Goalkeeper is always in position 1
            if (stadiumPosition === 1) {
                player.position = 'Goalkeeper';
                continue;
            }
    
            // Assign Defenders
            if (stadiumPosition >= 2 && stadiumPosition < 2 + defenderCount) {
                player.position = 'Defender';
            }
            // Assign Midfielders
            else if (stadiumPosition >= 2 + defenderCount && stadiumPosition < 2 + defenderCount + midfielderCount) {
                player.position = 'Midfielder';
            }
            // Assign Forwards
            else if (stadiumPosition >= 2 + defenderCount + midfielderCount) {
                player.position = 'Forward';
            }
        }
        const transformedPlayers = {
            players: Object.values(assignedPlayers).map((player) => {
              // Type assertion to tell TypeScript that player is of type PlayerGameInit
              const p = player as PlayerGameInit;  
              return {
                id: p.id,
                position: p.position,
                stadium_index_position: p.stadium_index_position,
                starter: true, // assuming all players are starters
              };
            })
          };
        return transformedPlayers;
    };
    
    
    
    const getPositionIndex = (positionKey: string) => {
        const [role, ...indexes] = positionKey.split('-');
    
        if (role === 'goalkeeper') return 1;
    
        const defenderOffset = 2;
        const midfielderOffset = defenderOffset + defenders;
        const forwardOffset = midfielderOffset + midfieldRows.reduce((acc, count) => acc + count, 0);
        switch (role) {
            case 'defender':
                return defenderOffset + parseInt(indexes[0], 10);
            case 'midfielder':
                if (indexes.length === 1) {
                    // Single index, assuming it's a single row of midfielders
                    return midfielderOffset + parseInt(indexes[0], 10);
                } else {
                    // Two indexes, handle it as midfielder-rowIndex-playerIndex
                    const rowIdx = parseInt(indexes[0], 10);
                    const playerIdx = parseInt(indexes[1], 10);
                    const previousRowsCount = midfieldRows.slice(0, rowIdx).reduce((acc, count) => acc + count, 0);
                    return midfielderOffset + previousRowsCount + playerIdx;
                }
            case 'forward':
                return forwardOffset + parseInt(indexes[0], 10);
            default:
                return null;
        }
    };
    const getNextAvailablePosition = () => {
        const positionsOrder = [
            'goalkeeper',
            ...Array.from({ length: defenders }, (_, idx) => `defender-${idx}`),
            ...midfieldRows.flatMap((count, rowIdx) => Array.from({ length: count }, (_, idx) => `midfielder-${rowIdx}-${idx}`)),
            ...Array.from({ length: forwards }, (_, idx) => `forward-${idx}`)
        ];
    
        return positionsOrder.find(position => !assignedPlayers[position]);
    };
    useEffect(() => {
        removeAllPlayers();
      }, [formation]);
    const handlePlayerClick = (player: PlayerGameInit) => {
    const nextPosition = getNextAvailablePosition();
    if (nextPosition) {
        const stadiumIndexPosition = getPositionIndex(nextPosition);
        setAssignedPlayers((prev) => ({
            ...prev,
            [nextPosition]: { ...player, stadium_index_position: stadiumIndexPosition },
        }));

        setPlayerList((prevList) =>
            prevList.filter((p) => p.id !== player.id).sort((a, b) => a.number - b.number)
        );
    }
};
const handleDragStart = (e: React.DragEvent<HTMLDivElement>, player: PlayerGameInit) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(player));
};
const handleDrop = (e: React.DragEvent<HTMLDivElement>, positionKey: string) => {
    const playerData = e.dataTransfer.getData("text/plain");
    const newPlayer = JSON.parse(playerData);

    // Check if there's already a player at the position, if so, return the old player to the list
    const currentPlayer = assignedPlayers[positionKey];

    if (currentPlayer) {
        setPlayerList((prevList) => 
            [...prevList, currentPlayer].sort((a, b) => a.number - b.number)
        );
    }

    const stadiumIndexPosition = getPositionIndex(positionKey);
    setAssignedPlayers((prev) => ({
        ...prev,
        [positionKey]: { ...newPlayer, stadium_index_position: stadiumIndexPosition },
    }));

    setPlayerList((prevList) =>
        prevList.filter((p) => p.id !== newPlayer.id).sort((a, b) => a.number - b.number)
    );
};

const removePlayer = (positionKey: string) => {
    const playerToRemove = assignedPlayers[positionKey];

    if (playerToRemove) {
        setPlayerList((prevList) =>
            [...prevList, playerToRemove].sort((a, b) => a.number - b.number)
        );

        setAssignedPlayers((prev) => ({
            ...prev,
            [positionKey]: null,
        }));
    }
};

const removeAllPlayers = () => {
    setAssignedPlayers({});
    setPlayerList(
        [
            ...playerList,
            ...Object.values(assignedPlayers).filter(
                (player): player is PlayerGameInit => player !== null
            )
        ].sort((a, b) => a.number - b.number)
    );
};
    
    return (
        <Container>
            <FieldContainer>
            <StyledSoccerField> <SoccerField borderRadius={3} lineColor="grey" /></StyledSoccerField>
            
                <PlayerColumn>
                <PlayerIcon
                    onDrop={(e) => handleDrop(e, 'goalkeeper')}
                    onDragOver={(e) => e.preventDefault()}
                    onClick={() => removePlayer('goalkeeper')}
                >
                    <ShirtIcon src={shirtBlueIcon} alt="Shirt Icon" />
                    
                    {assignedPlayers.goalkeeper ? (
                        <>
                            <PlayerNumber style={{ color: 'white' }}>{assignedPlayers.goalkeeper.number}</PlayerNumber>
                            <PlayerName >{assignedPlayers.goalkeeper.lastName}</PlayerName>
                        </>
                    ) : (
                        <PlayerName>GK</PlayerName> // Display 'GK' as a label when no player is assigned
                    )}
                </PlayerIcon>
                </PlayerColumn>

                <PlayerColumn>
                    {Array(defenders).fill(null).map((_, idx) => {
                        const playerKey = `defender-${idx}`;
                        const player = assignedPlayers[playerKey];
        
                        return (
                            <PlayerIcon
                                key={playerKey}
                                
                                onDrop={(e) => handleDrop(e, playerKey)}
                                onDragOver={(e) => e.preventDefault()}
                                onClick={() => removePlayer(playerKey)}
                            >
                                <ShirtIcon src={shirtBlueIconBorder} alt="Shirt Icon" />
                                {player && (
                                    <>
                                        <PlayerNumber>{player.number}</PlayerNumber>
                                        <PlayerName>{player.lastName}</PlayerName>
                                    </>
                                )}
                            </PlayerIcon>
                        );
                    })}
                </PlayerColumn>

                {midfieldRows.map((count, rowIdx) => (
                    <PlayerColumn key={`midfield-row-${rowIdx}`}>
                        {Array(count).fill(null).map((_, idx) => {
                            const playerKey = `midfielder-${rowIdx}-${idx}`;
                            const player = assignedPlayers[playerKey];
                            return (
                                <PlayerIcon
                                    key={playerKey}
                                    onDrop={(e) => handleDrop(e, playerKey)}
                                    onDragOver={(e) => e.preventDefault()}
                                    onClick={() => removePlayer(playerKey)}
                                >
                                    <ShirtIcon src={shirtBlueIconBorder} alt="Shirt Icon" />
                                    {player && (
                                        <>
                                            <PlayerNumber>{player.number}</PlayerNumber>
                                            <PlayerName>{player.lastName}</PlayerName>
                                        </>
                                    )}
                                </PlayerIcon>
                            );
                        })}
                    </PlayerColumn>
                ))}

                <PlayerColumn>
                    {Array(forwards).fill(null).map((_, idx) => {
                        const playerKey = `forward-${idx}`;
                        const player = assignedPlayers[playerKey];

                        return (
                            <PlayerIcon
                                key={playerKey}
                                onDrop={(e) => handleDrop(e, playerKey)}
                                onDragOver={(e) => e.preventDefault()}
                                onClick={() => removePlayer(playerKey)}
                            >
                                <ShirtIcon src={shirtBlueIconBorder} alt="Shirt Icon" />
                                {player && (
                                    <>
                                        <PlayerNumber>{player.number}</PlayerNumber>
                                        <PlayerName>{player.lastName.slice(0, 10)}</PlayerName>
                                    </>
                                )}
                            </PlayerIcon>
                        );
                    })}
                </PlayerColumn>
            </FieldContainer>
            <PlayersList>
                <PositionSection>
                    <PositionTitle>GKs</PositionTitle>
                    <PositionColumn>
                        {goalkeepers.map(player => (
                            <PlayerItem
                                key={player.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, player)}
                                onClick={() => handlePlayerClick(player)}
                            >
                                <img src={shirtBlueIcon} alt="Player shirt" style={{ width: '100%' }} />
                                <PlayerNumber2 style={{ color: 'white' }}>{player.number}</PlayerNumber2>
                                <PlayerName2>{player.lastName.slice(0, 10)}</PlayerName2>
                            </PlayerItem>
                        ))}
                    </PositionColumn>
                </PositionSection>

                <PositionSection>
                    <PositionTitle>DEFENDERS</PositionTitle>
                    <PositionColumn>
                        {defendersList.map(player => (
                            <PlayerItem
                                key={player.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, player)}
                                onClick={() => handlePlayerClick(player)}
                            >
                                <img src={shirtBlueIconBorder} alt="Player shirt" style={{ width: '100%' }} />
                                <PlayerNumber2>{player.number}</PlayerNumber2>
                                <PlayerName2>{player.lastName.slice(0, 10)}</PlayerName2>
                            </PlayerItem>
                        ))}
                    </PositionColumn>
                </PositionSection>

                <PositionSection>
                    <PositionTitle>MIDFIELDERS</PositionTitle>
                    <PositionColumn>
                        {midfielders.map(player => (
                            <PlayerItem
                                key={player.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, player)}
                                onClick={() => handlePlayerClick(player)}
                            >
                                <img src={shirtBlueIconBorder} alt="Player shirt" style={{ width: '100%' }} />
                                <PlayerNumber2>{player.number}</PlayerNumber2>
                                <PlayerName2>{player.lastName.slice(0, 8)}</PlayerName2>
                            </PlayerItem>
                        ))}
                    </PositionColumn>
                </PositionSection>

                <PositionSection>
                    <PositionTitle>FORWARDS</PositionTitle>
                    <PositionColumn>
                        {forwardsList.map(player => (
                            <PlayerItem
                                key={player.id}
                                draggable
                                onDragStart={(e) => handleDragStart(e, player)}
                                onClick={() => handlePlayerClick(player)}
                            >
                               <img src={shirtBlueIconBorder} alt="Player shirt" style={{ width: '100%' }} />
                               <PlayerNumber2>{player.number}</PlayerNumber2>
                               <PlayerName2>{player.lastName.slice(0, 7)}</PlayerName2>
                            </PlayerItem>
                        ))}
                    </PositionColumn>
                </PositionSection>
            </PlayersList>
            <Options>
                <FormControl fullWidth className={classes.formControl} style={{ margin: '0px 0px 0px 10px' }}>
                                <InputLabel id="demo-simple-select-label">Formation</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label = "Formation"
                                    onChange={(event) => setFormation(event.target.value as string)}
                                    value={formation}
                                >
                                    {
                                        formations.map((formation) => {
                                            return <MenuItem key={formation} value={formation}>{formation}</MenuItem>
                                        })
                                    }
                                </Select>
                </FormControl>     
                <Button className={classes.bottomButtonBack} onClick={() => (setCurrentPage(5))}> ADD PLAYER 
                <img 
                    src={attIcon} 
                    alt="attacker icon" 
                    style={{ marginLeft: 8, width: 20, height: 20 }} // adjust size and spacing as needed
                />
                </Button>
                <Button className={classes.bottomButtonBack} onClick={() => (setCurrentPage(1))}>BACK</Button>
                <Button className={classes.bottomButtonCancel} onClick={() => (removeAllPlayers())}>CLEAR SELECTION</Button>
                <Button 
                    className={classes.bottomButtonNext} 
                    disabled={Object.keys(assignedPlayers).length !== 11} 
                    onClick={() => {
                        const validatedPlayers = validatePlayersPosition(assignedPlayers, defenders, midfielders);
                        submitGame(dispatch, teamId, season, validatedPlayers);
                    }}
                >
                    CREATE GAME
                </Button>
            </Options>
            
        </Container>
      );
    };

    const Container = styled.div`
    display: grid; 
    grid-template-columns: 1fr; 
    grid-template-rows: 0.15fr 1.1fr 0.75fr; 
    gap: 0px; 
    grid-template-areas: 
      "Options "
      "FieldContainer "
      "PlayersList "; 
  
    background-color: white;
    padding: 0;
    width: 100vw;
    max-height: 100vh;
    border-radius: 8px;
    align-items: center; /* Centers items vertically */
    justify-items: center; /* Centers items horizontally */
    justify-content: center;
  `;
  
const FieldContainer = styled.div`
  grid-area: FieldContainer;
  margin: 30px 0 0 0;
  display: flex;
  align-items: center; /* Center content vertically within the container */
  justify-content: center; /* Center content horizontally within the container */
  padding: 0; /* Remove padding to allow the field to use full space */
  width: 80%; /* Use full width of the container */
  height: 100%; /* Use full height of the container */
  max-height: 400px;
  border-radius: 20px;
  position: relative; /* Ensure child elements are positioned relative to this container */
`;

const StyledSoccerField = styled.div`
    position: absolute; /* Position absolutely to fill the container */
    top: 0; /* Align to the top */
    left: 0; /* Align to the left */
    right: 0; /* Stretch to the right */
    bottom: 0; /* Stretch to the bottom */
    z-index: 1; /* Ensure it is behind player icons */
`;

const PlayersList = styled.div`
  grid-area: PlayersList;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: white;
  padding: 10px;
  width: 100%;
  max-height: 600px;
  border-radius: 8px;
  font: 1rem "Karla", italic;
`;
const Options = styled.div`
  grid-area: Options;
  display: flex;
  margin: 10px 0 0 0 ;
  flex-direction: row;
  width: 90%;
  align-items: center; 
  justify-content: center;
`;

const PlayerColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 50px 0px 0px ;
  padding: 0% 5% 1% 0;
  z-index: 2;
`;

const Players = styled.div`
  background-color: ${WHITE};
  color: ${DARK_BLUE};
  border: 2px solid ${DARK_BLUE};
  border-radius: 50%;
  width: 100px;
  height: 30px;
  display: flex;
  flex-direction: column;  /* Stack content vertically */
  align-items: center;      /* Center horizontally */
  justify-content: center;  /* Center vertically */
  text-align: center;       /* Center text */
  line-height: 1.2;         /* Adjust line height for better spacing */
  margin: 8px auto;        /* Center the component */
  cursor: pointer;
`;

const PlayerName = styled.div`
  position: absolute;
  bottom: -7px;   /* Adjust position as needed */
  z-index: 3; 
  color: ${DARK_BLUE};
  text-align: center;
  white-space: nowrap;
  font: italic 0.6rem "Karla Bold";
`;

const PlayerNumber = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3; // Ensure it's above the shirt
  transform: translate(-50%, -50%);
  color: ${DARK_BLUE};
  font: italic 1.2rem "Karla Bold";
  z-index: 1000;
`;



const Goalkeeper = styled(Players)`
  background-color: ${DARK_BLUE};
  color: ${WHITE};
`;

const PositionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 5px 0 5px ;
  border-radius: 5px;
`;

const PositionColumn = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  grid-auto-flow: column;
  gap: 0 0%;
  justify-items: center;
  margin: 0 10px 10px 0px;
`;


const PositionTitle = styled.h3`
  color: ${GREY};
  margin-bottom: 10px;
  font: 1.2rem "Antique Olive";
  align-content:center;
  text-align: center;
`;
const PlayerItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${WHITE};
  color: ${DARK_BLUE};
  width: 50px;
  height: 50px;
  cursor: pointer;
  margin: 10px;
  font-size: 0.7rem;
  position: relative; /* To position name and number */
  text-align: center;
  background-image: url(${shirtBlueIconBorder});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
  &:hover {
    background-color: ${WHITE};
  }
`;
const PlayerName2 = styled.div`
  position: absolute;
  bottom: -18px;
  left: 50%;
  max-height: 1rem;
  transform: translateX(-50%);
  color: ${DARK_BLUE};
  font: italic 0.75rem "Karla Bold";
  text-align: center;
  white-space: nowrap;
  z-index: 3; /* Ensure it's above the shirt */
`;

const PlayerNumber2 = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${DARK_BLUE};
  font: italic 1.2rem "Karla Bold";
  text-align: center;
  z-index: 3; /* Ensure it's above the shirt */
`;
const PlayerIcon = styled.div`
display: flex;
  position: relative;
  width: 40px;  /* Adjust as needed */
  height: 60px; /* Adjust as needed */
  margin: 0 0 3px 0;
  cursor: pointer;
  justify-content: center; /* Center children horizontally */
  align-items: center;   /* Center children vertically */
`;
const ShirtIcon = styled.img`
  width: 120%;   /* Adjust size as necessary */
  height: auto;  /* Keep aspect ratio */
  z-index: 1; // Ensure it's above the shirt
`;
export default FieldPositions;
