import styled from "styled-components";
import {
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Select,
  MenuItem,
  Grid,
} from "@material-ui/core";
import { useState } from "react";
import { ArrowDownward, ArrowUpward, Search } from "@material-ui/icons";
import PlayerPositionCard from "../player-position-card/player-position-card";
import { PlayerStats } from "../../../models/player";
import { useSelector } from "react-redux";
import { selectTeamPlayers } from "../../../slices/playerSlice";
import store from "../../../app/store";
import { DARK_BLUE, GREY } from "../../../assets/colors";

const sortOptions = ["NAME", "NUMBER", "POSITION"];

const searchBarStyles = makeStyles(() => ({
  root: {
    height: "3rem",
    backgroundColor: "white",
    width: "100%",
    "&$focused $notchedOutline": {
      borderColor: DARK_BLUE,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const useStyles = makeStyles(() => ({
  arrow: {
    color: "black",
    height: "1.5rem",
    marginBottom: "-5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const SquadOverview = () => {
  const classes = useStyles();
  const [players, setPlayers] = useState<PlayerStats[]>(
    useSelector(selectTeamPlayers)
  );
  const [sortType, setSortType] = useState("");
  const [isReversed, setIsReversed] = useState(false);

  const applySearchFilter = (searchFilter: string) => {
    searchFilter = searchFilter.toLowerCase();
    const filteredPlayers: PlayerStats[] = [];
    for (const player of store.getState().playerSlice.teamPlayers) {
      if (player.name.toLowerCase().includes(searchFilter)) {
        filteredPlayers.push(player as PlayerStats);
      }
    }
    setPlayers(filteredPlayers);
  };

  const sortPlayers = (sortType: string) => {
    setIsReversed(false);
    const playersCopy = [...players];

    switch (sortType) {
      case "NAME":
        setPlayers(playersCopy.sort((a, b) => (a.name < b.name ? -1 : 1)));
        break;
      case "POSITION":
        const order = ["Goalkeeper", "Defender", "Midfielder", "Forward"];
        let orderIndex = 0;
        for (let i = 0; i < playersCopy.length; i++) {
          if (playersCopy[i].position !== order[orderIndex]) {
            for (let j = i + 1; j < playersCopy.length; j++) {
              if (playersCopy[j].position === order[orderIndex]) {
                const tempPlayer = playersCopy[i];
                playersCopy[i] = playersCopy[j];
                playersCopy[j] = tempPlayer;
                break;
              }
              if (j === playersCopy.length - 1) {
                orderIndex++;
                i--;
              }
            }
          }
        }
        setPlayers(playersCopy);
        break;
      case "NUMBER":
        setPlayers(playersCopy.sort((a, b) => a.number - b.number));
        break;
    }
  };

  const reverseArray = () => {
    const playerCopy = [...players];
    setPlayers(playerCopy.reverse());
    setIsReversed(!isReversed);
  };

  return (
    <Wrapper>
      <OutlinedInput
        classes={searchBarStyles()}
        placeholder="Search a player..."
        onChange={(event) => applySearchFilter(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Search style={{ color: GREY }} />
          </InputAdornment>
        }
      />
      <FiltersWrapper>
        <SortTypeWrapper>
          SORT BY:
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px", width: '8rem' }}
            value={sortType}
            onChange={(event) => {
              setSortType(event.target.value as string);
              sortPlayers(event.target.value as string);
            }}
          >
            {sortOptions.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </SortTypeWrapper>
        <SortTypeWrapper>
          Order:
          <ArrowWrapper>
            {isReversed ? (
              <ArrowUpward
                className={classes.arrow}
                onClick={() => reverseArray()}
              />
            ) : (
              <ArrowDownward
                className={classes.arrow}
                onClick={() => reverseArray()}
              />
            )}
          </ArrowWrapper>
        </SortTypeWrapper>
      </FiltersWrapper>
      <Grid container spacing={1}>
        {players.map((player: PlayerStats) => {
          return (
            <PlayerPositionCard
              id={player.id}
              name={player.name}
              number={player.number}
              position={player.position}
            />
          );
        })}
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 60vh;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`;
const SortTypeWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 10px;
  }
`;
const ArrowWrapper = styled.div`
  padding: 3px;
  border: 1px solid ${GREY};
  border-radius: 4px;
  background-color: white;
  margin-left: 10px;
`;

export default SquadOverview;
