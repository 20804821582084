import { createGlobalStyle } from 'styled-components';
import { latoRegular } from "./theme";
import { makeStyles } from '@material-ui/core';
import { DARK_BLUE, FOREST_GREEN, GREY, GREY3, LIGHT_BLUE, LIGHT_GREY, RED, WHITE } from '../assets/colors';

export default createGlobalStyle`
    body{
        font-family:${latoRegular};
        margin: 0;
    }
`

export const sportsAiGlobalStyles = makeStyles((theme) => ({
  //----------- GENERAL  ------------
  root: {
    fontFamily: "Karla Bold",
  },
  logo: {
    height: "11rem",
    width: "11rem",
    marginTop: "3rem",
  },
  paper: {
    margin: theme.spacing(4, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  back: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    fontWeight: "bold",
    padding: "3px 15px",
    margin: "20px 15px",
    fontFamily: "Karla Bold",
    fontSize: "1rem",
  },
  formControl: {
    fontSize: "1rem",
    fontFamily: 'Karla',
    fontStyle: 'italic',
    //border: `0.5px solid ${DARK_BLUE}`, 
  },
  //-------------- TEXT  --------------
  link: {
    display: "flex",
    alignItems: "center",
    fontSize: "11pt",
  },
  errorText: {
    color: RED,
    display: "flex",
    alignItems: "center",
    fontSize: "11pt",
    textAlign: "center"
  },
  //------------ Buttons  ------------
  fullBlueButton: {
    fontSize: "1rem",
    color: WHITE,
    backgroundColor: DARK_BLUE,
    height: "44px",
    borderRadius: "8px",
    margin: "15px 0px",
    fontFamily: "Karla Bold",
    "&:disabled": {
      opacity: "40%",
      color: WHITE,
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: DARK_BLUE,
      color: WHITE,
    },
  },
  fullWhiteButton: {
    fontSize: "1rem",
    color: DARK_BLUE,
    backgroundColor: 'white',
    height: "50px",
    borderRadius: "8px",
    borderColor: DARK_BLUE,
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: LIGHT_GREY,
      color: DARK_BLUE,
    },
  },
  fullGreenButton: {
    fontSize: "1rem",
    color: WHITE,
    backgroundColor: FOREST_GREEN,
    height: "44px",
    borderRadius: "8px",
    margin: "15px 0px",
    fontFamily: "Karla Bold",
    "&:disabled": {
      opacity: "40%",
      color: WHITE,
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: DARK_BLUE,
      color: WHITE,
    },
  },
  fullGreyButton: {
    fontSize: "1rem",
    color: GREY,
    backgroundColor: LIGHT_GREY,
    height: "50px",
    borderRadius: "8px",
    margin: "5px 0px",
    fontFamily: "Karla Bold",
    "&:disabled": {
      opacity: "40%",
      color: WHITE,
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: LIGHT_GREY,
      color: GREY,
    },
  },
  fullWhiteButtonPL: {
    fontSize: "1rem",
    color: DARK_BLUE,
    backgroundColor: LIGHT_GREY,
    height: "50px",
    borderRadius: "8px",
    margin: "5px 0px",
    fontFamily: "Karla Bold",
    "&:disabled": {
      opacity: "40%",
      color: WHITE,
    },
    "&:hover": {
      cursor: "pointer",
      backgroundColor: LIGHT_GREY,
      color: DARK_BLUE,
    },
  },
  transparantButton: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    padding: "3px 15px",
    textTransform: "capitalize",
    fontWeight: "bold",
  },
  outlinedButton: {
    color: DARK_BLUE,
    fontSize: "0.8rem",
    margin: "1rem",
    fontWeight: 600,
    width: "28%",
    borderRadius: "4px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    marginBottom: "1rem",
    fontFamily: "Karla Bold",
  },
  tabButton: {
    textAlign: "center",
    width: "100%",
    borderRadius: "0px",
    color: GREY,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  tabButtonSelected: {
    textAlign: "center",
    width: "100%",
    borderRadius: "0px",
    borderBottom: "3px solid",
    borderColor: DARK_BLUE,
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  tabButtonDisabled: {
    textAlign: "center",
    width: "100%",
    borderRadius: "0px",
    borderBottom: "3px solid",
    color: GREY,
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  tabButtonSecondary: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    fontWeight: "bold",
    padding: "2px 15px",
    margin: "0px 10px",
    fontFamily: "Karla Bold",
  },
  tabButtonSecondarySelected: {
    backgroundColor: DARK_BLUE,
    color: "white",
    fontWeight: "bold",
    padding: "2px 15px",
    margin: "0px 10px",
    fontFamily: "Karla Bold",
    "&:hover": {
      backgroundColor: DARK_BLUE,
    },
  },
  tabButtonElement: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 0px 40px 0px",
    fontFamily: "Karla Bold",
    fontWeight: "bold",
    borderBottom: `3px solid ${DARK_BLUE}`, 
    borderRadius:'10px',
  },
  tabButtonSelectedElement: {
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    margin: "0px 0px 40px 0px",
    fontFamily: "Karla Bold",
    borderRadius:'10px',
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
  expandButtonFilter: {
    color: "black",
    fontFamily: "Karla Bold",
    borderBottom: "3px solid",
    border: "3px solid",
    borderColor: DARK_BLUE,
  },
  filterButtonExpand: {
    color: "white",
    fontFamily: "Karla Bold",
  },
  sizeFilterButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 5px",
    fontFamily: "Karla Bold",
    fontSize: "1rem",
  },
  sizeFilterButtonSelected: {
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
  bottomButtonNext: {
    color: WHITE ,
    backgroundColor:DARK_BLUE ,
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "20px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    margin: "5px",
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:DARK_BLUE ,
    },
  },
  bottomButtonNextDisabled: {
    color: WHITE ,
    backgroundColor:GREY3 ,
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "20px",
    border: "3px solid",
    borderColor: GREY3,
    margin: "5px",
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:GREY3 ,
    },
  },
  bottomButtonCancel: {
    color: DARK_BLUE ,
    backgroundColor:GREY3 ,
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "20px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    margin: "5px",
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:GREY3 ,
    },
  },
  bottomButtonBack: {
    color: DARK_BLUE ,
    backgroundColor:WHITE ,
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "20px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    margin: "5px",
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:WHITE ,
    },
  },
  lightBlueBotton: {
    color: DARK_BLUE ,
    backgroundColor:LIGHT_BLUE ,
    fontSize: "0.8rem",
    fontWeight: 600,
    width: "100%",
    borderRadius: "20px",
    border: "3px solid",
    borderColor: DARK_BLUE,
    margin: "5px",
    fontFamily: "Karla Bold",
    "&:hover": {
      cursor: "pointer",
      backgroundColor:WHITE ,
    },
  },
  //------------ Arrows  ------------
  orderArrow: {
    color: "black",
    height: "1.5rem",
    marginBottom: "-5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  nextPageArrow: {
    fontSize: 'large',
    color: DARK_BLUE,
  },
  disabledNextPageArrow: {
    color: GREY,
    fontSize: '20px',
  },
  menuHeader:{
    fontSize: "1rem",
    width: "100%",
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontStyle: "italic",
  },
  menuHeaderSelected:{
    textAlign: "center",
    width: "100%",
    color: DARK_BLUE,
    borderBottom: "3px solid",
    fontStyle: "italic",
    borderColor: DARK_BLUE,
    borderRadius: "0px",
    fontFamily: "Karla Bold",
    fontSize: "1rem",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  //----------- Dropdowns ------------
  subHeader:{
    fontSize: "0.5rem",
    color: DARK_BLUE,
    fontFamily: "Karla Bold",
    fontStyle: "italic",
  },
  //----------- Tooltip ------------
  tooltip:{
    fontSize: "1rem",  // Change font size
    fontFamily: "'Antique Olive Nord', sans-serif",  // Change font family
    color: "white",  // Tooltip text color
    padding: "8px 12px",  // Padding inside the tooltip
    borderRadius: "8px",  // Rounded corners
  },
}));

export const searchBarStyles = makeStyles(() => ({
  root: {
    height: "2.5rem",
    margin: "0 2rem",
    width: "calc(100% - 4rem)",
    "&$focused $notchedOutline": {
      borderColor: DARK_BLUE,
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const sportsAiOutlinedInput = makeStyles((theme) => ({
  root: {
    letterSpacing: "0.1rem",
    marginTop: "30px",
    "& $notchedOutline": {
      borderColor: DARK_BLUE,
    },
    "&$focused $notchedOutline": {
      borderColor: DARK_BLUE,
    },
  },
  focused: {},
  notchedOutline: {},
}));

export const sportsAiErrorOutlinedInput = makeStyles((theme) => ({
  root: {
    letterSpacing: "0.1rem",
    marginTop: "30px",
    "& $notchedOutline": {
      borderColor: RED,
    },
    "&$focused $notchedOutline": {
      borderColor: RED,
    },
  },
  focused: {},
  notchedOutline: {},
}));