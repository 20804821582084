import styled from "styled-components";
import {
  InputAdornment,
  makeStyles,
  OutlinedInput,
  Select,
  MenuItem,
} from "@material-ui/core";
import {
  ArrowDownward,
  ArrowUpward,
  KeyboardArrowDown,
  Search,
} from "@material-ui/icons";
import { useEffect, useState } from "react";
import Attacker_Icon from "../../../assets/images/Attacker_Icon.png";
import Defender_Icon from "../../../assets/images/Defender_Icon.png";
import Midfielder_Icon from "../../../assets/images/Midfielder_Icon.png";
import Goaltender_Icon from "../../../assets/images/Goaltender_Icon.png";
import {
  DARK_BLUE,
  GREY,
  LIGHT_BLUE,
  LIGHT_GREY,
  RED_TO_GREEN_GRADIENT,
} from "../../../assets/colors";
import BasicStat from "../../../components/game-page/basic-stat/basic-stat";
import RatioPieChart from "../../../components/game-page/ratio-pie-chart/ratio-pie-chart";
import FourSectionPieChart from "../../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import SectionFieldVertical from "../../../components/game-page/section-field-vertical/section-field-vertical";
import { useSelector } from "react-redux";
import { selectCurrentPlayer, selectGamePlayers} from "../../../slices/playerSlice";
import { MatchPlayer } from "../../../models/player";
import store from "../../../app/store";
import MostPassesTo from "../../../components/game-page/mostPassesTo/mostPassesTo";
import SuccessRatio from "../../../components/stats-base/success-ratio/success-ratio";
import { selectAccountType } from "../../../slices/userSlice";
import { TEAM_ACCOUNT, PLAYER_ACCOUNT, LEAGUE_ACCESS, TEAM_LEAGUE } from "../../../models/constant/accountType";
import BasicStatStack from "../../../components/game-page/basic-stat/basic-stat-stack";

const sortOptions = [
  "NUMBER",
  "NAME",
  "RATING",
  "POSITION",
  "PASS PRECISION",
  "BALL LOSS",
  "RECOVERIES",
];

const useStyles = makeStyles(() => ({
  arrowBtnSelected: {
    transform: "rotate(180deg)",
    animation: "rotate 300ms ease",
  },
  arrow: {
    color: "black",
    height: "1.5rem",
    marginBottom: "-5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const searchBarStyles = makeStyles(() => ({
  root: {
    height: "3rem",
    backgroundColor: "white",
    width: "100%",
    "&$focused $notchedOutline": {
      borderColor: DARK_BLUE,
    },
  },
  focused: {},
  notchedOutline: {},
}));

const GamePlayerStats =  () => {
  const thisplayer = useSelector(selectCurrentPlayer);
  const accountType = useSelector(selectAccountType);
  const [currentPlayer, setCurrentPlayer] = useState<number>(0);
  const [sortType, setSortType] = useState<string>("");
  const [isReversed, setIsReversed] = useState(false);
  const classes = useStyles();
  const [players, setPlayers] = useState<MatchPlayer[]>(
    useSelector(selectGamePlayers)
  );

  const applySearchFilter = (searchFilter: string) => {
    searchFilter = searchFilter.toLowerCase();
    let gamePlayersCopy = store.getState().playerSlice.gamePlayers;

    setPlayers(
      gamePlayersCopy.filter((player: MatchPlayer) =>
        player.name.toLowerCase().includes(searchFilter)
      )
    );
  };

  const sortPlayers = (sortType: string) => {
    setIsReversed(false);

    const playersCopy = [...players];

    switch (sortType) {
      case "NUMBER":
        setPlayers(playersCopy.sort((a, b) => a.number - b.number));
        break;
      case "NAME":
        setPlayers(playersCopy.sort((a, b) => (a.name < b.name ? -1 : 1)));
        break;
      case "RATING":
        setPlayers(playersCopy.sort((a, b) => (a.grade > b.grade ? -1 : 1)));
        break;
      case "POSITION":
        const order = ["Goalkeeper", "Defender", "Midfielder", "Forward"];
        let orderIndex = 0;
        for (let i = 0; i < playersCopy.length; i++) {
          if (playersCopy[i].position !== order[orderIndex]) {
            for (let j = i + 1; j < playersCopy.length; j++) {
              if (playersCopy[j].position === order[orderIndex]) {
                const tempPlayer = playersCopy[i];
                playersCopy[i] = playersCopy[j];
                playersCopy[j] = tempPlayer;
                break;
              }
              if (j === playersCopy.length - 1) {
                orderIndex++;
                i--;
              }
            }
          }
        }
        setPlayers(playersCopy);
        break;
      case "PASS PRECISION":
        setPlayers(
          playersCopy.sort((a, b) =>
            a.passPrecision > b.passPrecision ? -1 : 1
          )
        );
        break;
      case "BALL LOSS":
        setPlayers(
          playersCopy.sort(
            (a, b) =>
              a.ballLosses.reduce((accumulator, current) => {
                return accumulator + current;
              }, 0) -
              b.ballLosses.reduce((accumulator, current) => {
                return accumulator + current;
              }, 0)
          )
        );
        break;
      case "RECOVERIES":
        setPlayers(
          playersCopy.sort(
            (a, b) =>
              a.ballRecoveries.reduce((accumulator, current) => {
                return accumulator + current;
              }, 0) -
              b.ballRecoveries.reduce((accumulator, current) => {
                return accumulator + current;
              }, 0)
          )
        );
        break;
    }
  };

  const reverseArray = () => {
    const playerCopy = [...players];
    setPlayers(playerCopy.reverse());
    setIsReversed(!isReversed);
  };

  const getPlayerIcon = (position: string) => {
    switch (position.toLowerCase()) {
      case "forward":
        return Attacker_Icon;
      case "defender":
        return Defender_Icon;
      case "midfielder":
        return Midfielder_Icon;
      case "goalkeeper":
        return Goaltender_Icon;
      default:
        return "";
    }
  };

  const getColor = (percentage: number) => {
    return RED_TO_GREEN_GRADIENT[
      Math.round(
        Math.min(
          percentage * (RED_TO_GREEN_GRADIENT.length - 1),
          RED_TO_GREEN_GRADIENT.length - 1
        )
      )
    ];
  };

  const handleClick = (playerId: number) => {
    if (playerId === currentPlayer) setCurrentPlayer(-1);
    else setCurrentPlayer(playerId);
  };

  return (
    <Wrapper>
      { (accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE ) && 
      <>
        <OutlinedInput
          classes={searchBarStyles()}
          placeholder="Search a player..."
          onChange={(event) => applySearchFilter(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <Search style={{ color: GREY }} />
            </InputAdornment>
          }
        ></OutlinedInput>
        <FiltersWrapper>
          <SortTypeWrapper>
            Sort by:
            <Select
              variant="outlined"
              style={{ height: "2rem", marginLeft: "5px", width: '12rem' }}
              value={sortType}
              onChange={(event) => {
                setSortType(event.target.value as string);
                sortPlayers(event.target.value as string);
              }}
            >
              {sortOptions.map((value, index) => {
                return (
                  <MenuItem key={value} value={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </SortTypeWrapper>
          <SortTypeWrapper>
            
            <ArrowWrapper>
              {isReversed ? (
                <ArrowUpward
                  className={classes.arrow}
                  onClick={() => reverseArray()}
                />
              ) : (
                <ArrowDownward
                  className={classes.arrow}
                  onClick={() => reverseArray()}
                />
              )}
            </ArrowWrapper>
          </SortTypeWrapper>
        </FiltersWrapper>
      </>
      }
      <TableRow
        style={{
          border: "none",
          backgroundColor: "transparent",
          height: "fit-content",
          margin: "0 1rem 10px",
        }}
      >
        <TableTitle style={{ justifyContent: "start" }}>#</TableTitle>
        <TableTitle style={{ justifyContent: "start" }}>NAME</TableTitle>
        <TableTitle>POSITION</TableTitle>
        <TableTitle>RATING</TableTitle>
        <TableTitle>PASS %</TableTitle>
        <TableTitle>BALL LOSS</TableTitle>
        <TableTitle>RECOVERIES</TableTitle>
      </TableRow>
      {players.map((player: MatchPlayer) => {
        if((accountType === PLAYER_ACCOUNT && player.name === thisplayer!.name) || (accountType === TEAM_ACCOUNT || accountType === LEAGUE_ACCESS || accountType === TEAM_LEAGUE ))
         return (
          <PlayerCardWrapper>
            <TableRow onClick={() => handleClick(player.id)}>
              <PlayerNumber style={{ justifyContent: "start" }}>
                {player.number} 
              </PlayerNumber>
              <PlayerName style={{ justifyContent: "start" }}>
                {player.name}
              </PlayerName>
              <div>
                <PlayerPositionIcon src={getPlayerIcon(player.position)} />
              </div>
              <PlayerStat
                style={{ color: getColor(player.grade / 100) }}
              >
                {Math.round(player.grade)}%
              </PlayerStat>
              <PlayerStat
                style={{ color: getColor(player.passPrecision / 100) }}
              >
                {Math.round(player.passPrecision)}%
              </PlayerStat>
              <PlayerStat>
                {player.ballLosses.reduce((partialSum, a) => partialSum + a)}
              </PlayerStat>
              <PlayerStat>
                {player.ballRecoveries.reduce(
                  (partialSum, a) => partialSum + a
                )}
              </PlayerStat>
              
              {(accountType === TEAM_ACCOUNT  || accountType === TEAM_LEAGUE) && 
                <div style={{ justifyContent: "end" }}>
                <KeyboardArrowDown
                  className={
                    player.id === currentPlayer ? classes.arrowBtnSelected : ""
                  }
                  style={{ color: DARK_BLUE }}
                />
              </div>
              }
            </TableRow>
            {player.id === currentPlayer || accountType === PLAYER_ACCOUNT ? (
              <PlayerStatWrapper>
                <HeaderWrapper>
                  <div>PASSES</div>
                </HeaderWrapper>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <FourSectionPieChart
                    title={"pass orientation (%)"}
                    orientation={player.passOrientation}
                    successRatio={[]}
                  />
                 <FourSectionPieChart
                    title={"pass success (%)"}
                    orientation={player.passOrientation}
                    successRatio={player.passOrientationSuccess}
                  />
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: " 1fr 1fr 1fr 1fr",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >

                  <SuccessRatio title={'PASSES %'} successes={player.successfulPasses} total={player.totalPasses}/>
                  <BasicStat title={'KEY PASSES'} value={player.keyPasses}/>
                  <BasicStatStack
                    title={"ASSISTS"}
                    value={player.assists}
                    title2={"2nd ASSIST"}
                    value2={player.secondAssists}
                  ></BasicStatStack>
                  <BasicStat
                    title={"Crosses"}
                    value={player.crosses}
                  ></BasicStat>
                </div>
                <MostPassesTo
                  passes={player.mostPassesTo}
                  setCurrentPlayer={setCurrentPlayer}
                />
                <HeaderWrapper>
                  <div>DEFENSE</div>
                </HeaderWrapper>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 2fr 2fr 1fr",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      gridTemplateRows: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                  <BasicStat title={"Fouls"} value={player.fouls}/>
                  <BasicStat title={"Offsides"} value={player.offsides}/>
                  </div>
                  <RatioPieChart
                    title={"CHALLENGES %"}
                    ratio={player.successfulChallenges / player.totalChallenges}
                  />
                  <RatioPieChart
                    title={"TACKLES %"}
                    ratio={player.successfulTackles / player.totalTackles}
                  />
                  <div
                    style={{
                      display: "grid",
                      gridTemplateRows: "1fr 1fr",
                      gap: "1rem",
                    }}
                  >
                  <BasicStat title={"Shot Blocks"} value={player.shotBlocks}/>
                  <BasicStat title={"Clearances"} value={player.clearances}/>
                  </div>
                </div>
                <FieldWrapper>
                  <SectionFieldVertical
                    title={"ball recoveries"}
                    data={player.ballRecoveries}
                    color={"blue"}
                  />
                  <SectionFieldVertical
                    title={"ball loss reactions"}
                    data={player.ballLossReactions}
                    color={"red_blue"}
                  />
                </FieldWrapper>
                <HeaderWrapper>
                  <div>SHOTS</div>
                </HeaderWrapper>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr 1fr 1fr",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <BasicStat title={"SHOTS"} value={player.shots}></BasicStat>
                  <BasicStat title={"GOALS"} value={player.goals}></BasicStat>
                  <BasicStat
                    title={"PENALTIES"}
                    value={player.penalties}
                  ></BasicStat>
                  <BasicStat
                    title={"FREE KICKS"}
                    value={player.directFreeKicks}
                  ></BasicStat>
                </div>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gap: "1rem",
                    marginBottom: "1rem",
                  }}
                >
                  <RatioPieChart
                    title={"SHOTS %"}
                    ratio={player.shotSuccessRatio / 100}
                  ></RatioPieChart>
                  <SuccessRatio
                    title={"DRIBBLES"}
                    successes={player.dribblesSuccess}
                    total={player.dribbles}
                  />
                </div>
              </PlayerStatWrapper>
            ) : (
              <></>
            )}
          </PlayerCardWrapper>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  min-height: 60vh;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
`;
const SortTypeWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 10px;
  }
`;
const ArrowWrapper = styled.div`
  padding: 3px;
  border: 1px solid ${GREY};
  border-radius: 4px;
  background-color: white;
  margin-left: 10px;
`;
const TableTitle = styled.div`
  text-align: center;
`;
const PlayerCardWrapper = styled.div`
  background-color: white;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 0rem 1rem;
`;
const TableRow = styled.div`
  display: grid;
  grid-template: auto / 0.4fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  height: 3rem;
  align-items: center;
  > div {
    display: flex;
    justify-content: center;
  }
  &: hover {
    cursor: pointer;
  }
`;
const PlayerName = styled.div`
  color: ${DARK_BLUE};
  text-transform: uppercase;
`;
const PlayerPositionIcon = styled.img`
  text-align: center;
`;
const PlayerStat = styled.div`
  color: ${GREY};
`;
const PlayerStatWrapper = styled.div`
  width: 100%;
  align-items: center;
`;
const HeaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: ${DARK_BLUE};
  font-size: 1rem;
  font-family: Antique Olive Nord;
  text-transform: uppercase;
  margin-top: 2rem;
  margin-bottom: 1rem;
  
    padding: 0 0 10px 0;
`;
const FieldWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin-bottom: 1rem;
`;
const PlayerNumber = styled.div`
  color: ${DARK_BLUE};
`;

export default GamePlayerStats;
