enum Branch {
  DEV = "dev",
  MAIN = "main",
  LOCAL = "local"
}

enum BaseUrl {
  DEV = "https://dev01.backend.sportsai.ca/",
  PROD = "https://backend.sportsai.ca/",
  LOCAL = "http://127.0.0.1:8000/",
}

// set .env variable REACT_APP_BRANCH to dev for dev base url and main for prod main url

var BASE_URL: string;
if (process.env.REACT_APP_BRANCH === Branch.DEV)
  BASE_URL = BaseUrl.DEV;
if (process.env.REACT_APP_BRANCH === Branch.MAIN)
  BASE_URL = BaseUrl.PROD; 
if (process.env.REACT_APP_BRANCH === Branch.LOCAL)
  BASE_URL = BaseUrl.LOCAL; 


enum ENDPOINT {
  REGISTER = "/api/auth/register",
  LOGIN = "/api/auth/login",
  LOGOUT = "/api/auth/logout",
  GET_USER = "/api/auth/user",
  UPDATE_USER = "/api/auth/user",
  COLLECT = "/api/auth/collect",
  GET_TEAM = "api/teams",
  GET_TEAM_STATS = "api/teams/get_teams_stats",
  GET_TEAM_COMPETITIONS = "api/teams/competitions",
  GET_TEAM_OPPONENTS = "api/teams/opponents",
  UPDATE_TEAM = "api/teams/update_team/",
  MATCH = "/api/matchs",
  CREATE_MATCH = "/api/matchs/create_match/",
  GET_PRESIGNED_URL = "/api/matchs/get_presigned_url/",
  UPDATE_MATCH_VIDEO_KEY = "/api/matchs/update_video_key/",
  MATCH_STATS = "/api/matchs/get_match_stats",
  GAME_LIST = "api/matchs/get_team_matches",
  PLAYER_LIST = "api/players",
  TEAM_SQUAD = "api/players/get_squad",
  PLAYERS_INFOS = "api/players/get_players_infos",
  PLAYER_PERFORMANCES = "api/players/get_players_performances",
  PLAYER_CREATE = 'api/players/create_player',
  PLAYER_DELETE = 'api/players/delete_player',
  PLAYER_STATS = "api/players/get_player_stats",
  PLAYER_MATCH = "api/players_game/get_by_match",
  PLAYER_STADIUM = "api/players_game/get_stadium_players",
  USER_SESSION = "/api/user_tracks/post_user_session/",
  VIDEO = "views/video/",
  EVENT = "views/video/get_events/",
  PLAYLIST_CREATE = "views/playlist/create/",
  PLAYLIST_COMPUTE = "views/playlist/compute",
  PLAYLIST_GET = "views/playlist/get/",
  PLAYLIST_DELETE = "views/playlist/delete/",
  COMPETITION_PLAYER_STATS = "api/competition/players/stats",
  COMPETITION_PLAYER_STAT = "api/competition/players/stat",
  COMPETITION_CLUB_STATS = "api/competition/club/stats",
  COMPETITION_CLUB_STAT = "api/competition/club/stat",
  COMPETITION_UPDATE = "api/competition/update",
  COMPETITION_CREATE = "api/competition/addcompetition/",
  ADD_OPPONENTS = "api/competition/addopponent/",
  CREATE_PLAYERS_GAME = "api/players_game/createPlayersGame/"
}

export {ENDPOINT, BASE_URL};
