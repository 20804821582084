import React from 'react';
import { Tooltip as MaterialTooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { GREY, WHITE, DARK_BLUE } from '../assets/colors'; // Adjust to your color imports

interface TooltipCustoProps {
  description: string;
}

const HtmlTooltip = styled(({ className, ...props }) => (
  <MaterialTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: WHITE, 
    color: DARK_BLUE, 
    maxWidth: 220,
    fontSize: '1rem', 
    fontFamily: 'Karla',
    fontStyle: 'italic',
    border: '1px solid #dadde9', 
  },
}));

const TooltipCustom: React.FC<TooltipCustoProps> = ({ description }) => {
  return (
    <HtmlTooltip title={description} arrow>
      <HelpOutlineIcon sx={{ color: GREY, cursor: 'pointer', fontSize: 24 }} />
    </HtmlTooltip>
  );
};

export default TooltipCustom;
