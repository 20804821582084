import axiosInstance from "./axios";
import { ENDPOINT } from "./config";

export const CompetitionApi = {
  getCompetitionPlayersStats: async (competition: number, season: number, teamId: number) => axiosInstance.get(`${ENDPOINT.COMPETITION_PLAYER_STATS}`,
  {
    params: {
      competition: competition,
      season: season,
      teamId: teamId
    }
  }
  ),
  getPlayersTable: async (competition: number, season: number, teamId: number, stat: string) => axiosInstance.get(`${ENDPOINT.COMPETITION_PLAYER_STAT}`,
  {
    params: {
      competition: competition,
      season: season,
      teamId: teamId,
      stat: stat
    }
  }
  ),
  getCompetitionClubStats: async (competition: number, season: number) => axiosInstance.get(`${ENDPOINT.COMPETITION_CLUB_STATS}`,
      {
        params: {
          competition: competition,
          season: season
        }
      }
    ),
    getClubTable: async (competition: number, season: number, stat: string) => axiosInstance.get(`${ENDPOINT.COMPETITION_CLUB_STAT}`,
      {
        params: {
          competition: competition,
          season: season,
          stat: stat
        }
      }
    ),
    updateCompetition: async (data: any) => axiosInstance.put(`${ENDPOINT.COMPETITION_UPDATE}`, data),
    createCompetition: async (data: any) => axiosInstance.post(`${ENDPOINT.COMPETITION_CREATE}`, data),
    addOpponent: async (data: any) => axiosInstance.post(`${ENDPOINT.ADD_OPPONENTS}`, data)};