import styled from "styled-components";
import emailjs from "@emailjs/browser";
import { Button, Checkbox, Dialog, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, Snackbar, TextField, Tooltip, Typography, makeStyles } from "@material-ui/core"
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../app/store";
import { addCompetition, selectCompetitions, selectTeamId } from "../../slices/teamSlice";
import { useSelector } from "react-redux";
import { Competition } from "../../models/competition";
import { teamApi } from "../../api/teamApi";
import { TeamSummary } from "../../models/team";
import { matchApi } from "../../api/matchApi";
import {playerApi} from "../../api/playerApi"
import { getGamesSummary } from "../../slices/gamesSlice";
import { selectSeason } from "../../slices/userSlice";
import ModalTopbar from "../../components/navigation/modalTopbar";
import { soccerFormations } from "../../models/constant/formations";
import { DARK_BLUE, WHITE } from "../../assets/colors";
import IconButton from "@mui/material/IconButton";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import Alert from "@mui/material/Alert";
import { CompetitionApi } from "../../api/competition";
import FieldPositions from "../../components/fieldAddGame/field";
import { selectUserPlayerInfos } from "../../slices/playerSlice";
import {  Player, PlayerInfo } from "../../models/player";
import { CreatePlayerModal } from "../profile/Preferences/CreatePlayerModal";
import { Game } from "../../models/game";
import logo from "./../../assets/images/Logo_Ball.png";
import { COUNTRIES } from "../../models/constant/countries";

enum navPage {
    createGame = 1,
    createCompetition= 2,
    addOpponent = 3,
    formationPage = 4,
    addPlayer = 5,
    creatingGame= 6,
}
export const useStyles = makeStyles(() => ({

    addButton: {
        color: DARK_BLUE,
        width: "20%",
        height: "20%",
        marginBottom: "2.1rem",
        fontFamily: "Karla Bold",
    },

    formControl: {
        fontSize: "1rem",
        fontFamily: 'Karla',
        fontStyle: 'italic',
        //border: `0.5px solid ${DARK_BLUE}`, 
      },
      formControlText: {
        fontSize: "1rem",
        fontFamily: 'Karla',
        fontStyle: 'italic',
        borderRadius: '5px',
        "& .MuiInput-underline:before": { // Default state
            border: `2px solid ${DARK_BLUE} !important`,
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before": { // Hover state
          border: `2px solid ${DARK_BLUE} !important`,
        },
        "& .MuiInput-underline:after": { // Focused state
          border: `2px solid ${DARK_BLUE} !important`,
        },
        "& .MuiInputBase-root.Mui-disabled:before": { // Disabled state
            borderBottomStyle: "solid",
            border: `${DARK_BLUE} !important`,
        }
      },
    bottomButton: {
      color: WHITE,
      backgroundColor: DARK_BLUE,
      fontSize: "0.8rem",
      fontWeight: 600,
      width: "100%",
      borderRadius: "20px",
      border: "3px solid",
      borderColor: DARK_BLUE,
      fontFamily: "Karla Bold",
      "&:hover": {
    backgroundColor: DARK_BLUE, // Keeps the background color the same on hover
  },
    },
    bottomButtonCancel: {
      color: DARK_BLUE ,
      backgroundColor:WHITE ,
      fontSize: "0.8rem",
      fontWeight: 600,
      width: "100%",
      borderRadius: "20px",
      border: "3px solid",
      borderColor: DARK_BLUE,
      fontFamily: "Karla Bold",
    },
    grid1: {
        color: DARK_BLUE,
        fontSize: "1.3rem",
        fontFamily: "Karla ",
        display: 'flex', 
        alignItems: 'center',
        justifyContent: 'center',
        margin: '15px 0px 0px 0px'
      },
      grid2: {
        color: DARK_BLUE,
        fontSize: "1rem",
        fontFamily: "Karla ",
        borderColor: DARK_BLUE,
      },
      checkbox: {
        color: `${DARK_BLUE} !important`, // Set the color of the checkbox
        '&.Mui-checked': {
          color: `${DARK_BLUE} !important`, // Set the color of the checkbox when checked
        },
      },
  }));
  
export const CreateGame = ({ setIsCreateGameComp}) => {
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [currentPage, setCurrentPage] = useState(navPage.createGame);
    const teamId: number | undefined  = useSelector(selectTeamId);
    const season: number | undefined = useSelector(selectSeason);
    const players: PlayerInfo[] = useSelector(selectUserPlayerInfos);
    const formations: string[] = soccerFormations;
    const competitions: Competition[] = useSelector(selectCompetitions);
    const [competition, setCompetition] = useState<Competition | undefined>(competitions[0]);
    
    const [competitionName, setCompetitionName] = useState('');
    const [competitionCode, setCompetitionCode] = useState('');
    const [type, setType] = useState('Club');
    const [gender, setGender] = useState('MALE');
    const [country, setCountry] = useState('');
    const [category, setCategory] = useState('U12');
    const [divisionLevel, setDivisionLevel] = useState(1);
    const [matchDuration, setMatchDuration] = useState(90);
    const categories = ["U12", "U13", "U14", "U15", "U16", "U17", "U18", "U19", "U20", "U21", "U23", "OPN"];
    const [start_date, setSeasonStart] = useState('');
    const [end_date, setSeasonEnd] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>('');
    const [opponentName, setOpponentName] = useState('');
    const [opponentCode, setOpponentCode] = useState('');
    const [isUploadVideo, setIsUploadVideo] = useState(false);
    const [gameVideoId, setGameVideoId] = useState<number>(0);
    const [opponents, setOpponents] = useState<TeamSummary[]>([]);
    const [latestGame, setLatestGame] = useState<Game>()
    const [messageMatchCreated,setMessageMatchCreated] = useState("Match creation in progress... ")
    const [loading, setLoading] = useState(true); 
    // Fetch opponents when the component mounts and when the competition changes
    useEffect(() => {
      if (competition) {
          // Fetch opponents for the initially selected competition
          teamApi.getOpponents(competition.id).then((ret) => {
              setOpponents((ret.data as TeamSummary[]).filter(team => team.id !== teamId));
          }).catch(error => {
              console.error('Error fetching opponents:', error);
          });
      }
    }, [competition]); 

    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [opponent, setOpponent] = useState<TeamSummary | null>(null);
    const [isHome, setIsHome] = useState(false);
    const [formation, setFormation] = useState<string>('4-4-2');
    const [gameDay, setGameDay] = useState<number>(0);
    const [videoLink, setVideoLink] = useState('');
    const newPlayer = useState<Player>();
    const [formErrors, setFormErrors] = useState({
        teamId: teamId,
        opponent: "",
        date: "",
        time: "",
        formation: "",
        videoLink:"",
    });
    const [formErrorsOpp, setFormErrorsOpp] = useState({
        opponentName: "",
        opponentCode:""
    });

    const validateInput = () => {
        var isValid: boolean = true;
        var opponentError: string = ""
        var dateError: string = ""
        var timeError: string = ''
        var formationError: string = ""
        var videoLinkError = '';

        if (opponent === null) {
            isValid = false;
            opponentError = "Please Select an opponent for the game";
        }
        if (selectedDate === "") {
            isValid = false;
            dateError = "Please Select a valid date";
        }
        if (selectedTime === '') {
            isValid = false;
            timeError =  "Please select a valid time";
        }
        if (formation === "") {
            isValid = false;
            formationError = "Please select a valid formation";
        }
        else {
          const urlPattern = new RegExp(/^(ftp|http|https):\/\/[^ "]+$/);
          if (!urlPattern.test(videoLink)) {
              isValid = false;
              videoLinkError = "Please enter a valid video URL.";
          }
          

        setFormErrors({
            teamId: teamId,
            opponent: opponentError,
            date: dateError,
            time: timeError,
            formation: formationError,
            videoLink: videoLinkError,
        });
        return isValid
    }}

    const handleCompetitionAdded = (newCompetition: Competition) => {
      setCompetition(newCompetition);
    };

    const changeCompetition = (competition_id: number) => {
      const selectedCompetition = competitions.find(comp => comp.id === competition_id); // Find competition by id
      if (selectedCompetition) {
          setCompetition(selectedCompetition);
          teamApi.getOpponents(selectedCompetition.id).then((ret) => {
              setOpponents((ret.data as TeamSummary[]).filter(team => team.id !== teamId));
          });
      } else {
          console.error(`Competition with id ${competition_id} not found`);
      }
  };
  
    const handleSubmitAddCompetition = async () => {
      const competitionAddData = {
          name: competitionName,
          code: competitionCode,
          duration: matchDuration,
          type: type,
          gender: gender,
          country: country,
          category: category,
          divisionLevel: divisionLevel,
          season: season,
          start_date: start_date,
          end_date: end_date,
          teamId: teamId
        };
    // Validate inputs here (lengths, ranges, etc.)
    if (competitionName.length > 50) {
      setSnackbarMessage('Competition Name must be 50 characters or less.');
      setOpenSnackbar(true);
      return;
    }
    if (competitionCode.length > 5) {
      setSnackbarMessage('Competition Code must be 5 characters or less.');
      setOpenSnackbar(true);
      return;
    }
    if (matchDuration < 40 || matchDuration > 90) {
      setSnackbarMessage('Match duration must be between 40 and 90 minutes.');
      setOpenSnackbar(true);
      return;
    }
    if (!start_date || !end_date) {
      setSnackbarMessage('Choose season dates!');
      setOpenSnackbar(true);
      return;
    }
    // Submit the data (this can be a call to an API)
    const response = await CompetitionApi.createCompetition(competitionAddData);
    const newCompetition: Competition = {
      id: response.data.id, // Use the id returned from the API
      competition: competitionAddData.name,
      edition: competitionAddData.season,
      is_under_contract: false,
      // Add any other properties required by the Competition type
    };
    try {
      dispatch(addCompetition(newCompetition));
      setSnackbarMessage('Competition added successfully!');
    } catch (error: any) {
      // Check if the error response is available
      if (error.response && error.response.data && error.response.data.message) {
          // Display the error message returned by the server
          setSnackbarMessage(error.response.data.message);
          console.error('Error creating competition:', error);
      } else {
          // Fallback error message
          setSnackbarMessage('Error adding competition. Please try again.');
          console.error('Error adding competition. Please try again.', error);
      }
      console.error('Error creating competition:', error);
      } finally {
          setOpenSnackbar(true);
      }
        handleCompetitionAdded(newCompetition);
        setCurrentPage(navPage.createGame)
      };
      const submitGame = async (dispatch, teamId, season, players) => {
        if (!validateInput()) {
          console.log(formErrors);
          return;
        }
      
        setLoading(true);
        setCurrentPage(navPage.creatingGame);
      
        try {
          const matchResponse = await matchApi.createMatch({
            team_id: teamId,
            opponent_id: opponent?.id,
            game_day: gameDay,
            competition_id: competition!.id,
            home_stadium: isHome,
            date: selectedDate,
            kickoff: selectedTime,
            home_formation: formation,
            video_host: videoLink,
          });
      
          setGameVideoId(matchResponse.data["id"]);
          dispatch(getGamesSummary({ teamId, season }));
      
          const gameListResponse = await matchApi.getGameList(teamId, season);
          const highestIdGame = gameListResponse.data.reduce(
            (maxGame, currentGame) =>
              currentGame.id > maxGame.id ? currentGame : maxGame,
            gameListResponse.data[0]
          );
          setLatestGame(highestIdGame);
      
          try {
            const playerGameResponse = await playerApi.createPlayersGame(players, highestIdGame.id);
          } catch (error) {
            console.error("Error creating players game:", error);
            setLoading(false);
          }
      
          // EmailJS integration to send matchResponse
          const emailParams = {
            to_email: 'info@sportsai.ca', // Operations email
            subject: `New Match Created - ${matchResponse.data["id"]}`, // Subject line
            match_details: JSON.stringify(matchResponse.data, null, 2), // Match details formatted as JSON
          };
      
          emailjs
            .send(
              'service_ahbumo3', // Your EmailJS service ID
              'template_xyz123', // Your EmailJS template ID
              emailParams,
              '5uKiJIVfgAE6VQTHt' // Your EmailJS public key
            )
            .then(
              (result) => {
                console.log("Email sent successfully:", result.text);
              },
              (error) => {
                console.error("Error sending email:", error.text);
              }
            );
      
        } catch (error) {
          console.error("Error in submitting game:", error);
          setLoading(false);
        }
      
        setMessageMatchCreated("Your match has been created and will be analyzed within the next 48h");
        setTimeout(() => {
          setIsCreateGameComp(false);
        }, 5000);
      };
  
    const handleOpponentSubmit = async () => {
        var isValidOpponent: boolean = true;
        var opponentNameError: string = ""
        var opponentCodeError: string = ""
        // Reset form errors
        
        if (opponentName === "") {
            isValidOpponent = false;
            opponentNameError = "Team name is required.";
        }
        if (opponentName.length > 30) {
            isValidOpponent = false;
            opponentNameError = 'Team name must be 30 characters or less.';
        }
        if (!opponentCode) {
            isValidOpponent = false;
            opponentCodeError = "Team code is required.";
        }
        if (opponentCode.length > 3) {
            isValidOpponent = false;
            opponentCodeError = 'Team code must be 3 characters';
        }
        setFormErrorsOpp({
            opponentName: opponentNameError,
            opponentCode: opponentCodeError
        });
        //const response = await CompetitionApi.createCompetition(competitionAddData);
        
        const opponent = {
          name: opponentName,
          code: opponentCode
        };
        const opponentAddData = {
            opponent: opponent,
            competitionId: competition?.id,
        };
        try {
        // Await the API call to ensure completion before proceeding
        const response = await CompetitionApi.addOpponent(opponentAddData);
        setOpenSnackbar(true);
        // Check for successful addition
        if (response.status === 201) {
            setSnackbarMessage(response.data.message); // Display success message
        } else {
            // Handle other statuses (e.g., 200, 404, etc.)
            setSnackbarMessage(`Error: ${response.data.message || "Unexpected error occurred."}`);
        }

        setOpenSnackbar(true);
    } catch (error) {
        setSnackbarMessage("An error occurred while adding the opponent.");
        setOpenSnackbar(true);
    }
        // Reset form fields if needed
        setTimeout(() => {
          setCurrentPage(navPage.createGame);
          // Reset form fields if needed
          setOpponentName('');
          setOpponentCode('');
      }, 1000);
      };
    
    const renderPageContent = () => {
        switch(currentPage){
            case 1: 
                return(<FormContainer>
                    <Grid container spacing={1}>
                    <Grid item xs={3} className={classes.grid1}>
                            {formErrors.time && <ErrorText>{formErrors.date}</ErrorText>}
                            <label htmlFor="checkbox" > DATE </label>
                                <input
                                    type="date"
                                    id="dateInput"
                                    value={selectedDate}
                                    className={classes.formControlText}
                                    onChange={(event) => setSelectedDate(event.target.value)}
                                    style={{ marginLeft: '20px' }}
                                />
                        </Grid>
                        <Grid item xs={3} className={classes.grid1}>
                            {formErrors.time && <ErrorText>{formErrors.time}</ErrorText>}
                            <label htmlFor="checkbox" > TIME </label>
                                <input
                                    type="time"
                                    id="dateInput"
                                    value={selectedTime}
                                    className={classes.formControlText}
                                    onChange={(event) => setSelectedTime(event.target.value)}
                                    style={{ marginLeft: '20px' }}
                                />
                        </Grid>
                        <Grid item xs={3} className={classes.grid1}>
                            <label htmlFor="checkbox" style={{ flex: '0 0 50%', marginRight: '10px' }}> Game Day </label>
                                <input 
                                    type="number"
                                    id="filled-basic" 
                                    value={gameDay}
                                    className={classes.formControlText}
                                    onChange={(event) => setGameDay(+event.target.value)}
                                    style={{ width: '25%', flexShrink: 0 }} 
                                />
                        </Grid>
                        <Grid item xs={3} className={classes.grid1}>
                            <label htmlFor="checkbox"> HOME FIELD </label>
                            <Checkbox className={classes.checkbox}
                                id="checkbox" 
                                checked={isHome} 
                                onChange={() => {setIsHome(!isHome)}}
                                style={{ marginLeft: '10px' }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <FormControl fullWidth className={classes.formControl} style={{ margin: '20px 0px 0px 10px' }}>
                                <InputLabel id="demo-simple-select-label">Competition </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label = "Competition"
                                    value = {competition!.id}
                                    onChange={(event) => {changeCompetition(event.target.value as number)}}
                                >
                                    {
                                        competitions.map((competition, index) => {
                                            return <MenuItem key={competition.id} value={competition.id}>{competition.competition}</MenuItem>
                                        })
                                    }
                                    
                                </Select>
                                <FormHelperText></FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1} style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-evenly', alignItems: 'flex-end', alignContent:'flex-end' }}>
                        <Tooltip title={"Add competition"} >
                        <IconButton size="medium" className={classes.addButton} onClick={() => setCurrentPage(navPage.createCompetition)}>
                                <PlaylistAddIcon fontSize="medium" />
                            </IconButton>
                        </Tooltip>
                        </Grid>
                        <Grid item xs={5} style={{ height: '1.5rem' }}>
                            {formErrors.opponent && <ErrorText>{formErrors.opponent}</ErrorText>}
                            <Tooltip title={!competition ? "Choose a competition before adding opponents" : (opponents.length === 0 ? "Add opponents or change competition" : "")}>
                            <FormControl fullWidth className={classes.formControl} 
                            style={{ margin: '20px 0px 0px 10px' }} 
                            disabled={!competition || opponents.length === 0}  // Disable FormControl and all its child elements when competition is not selected
                                >
                                <InputLabel id="label">Opponent</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label = "Opponent"
                                    onChange={(event) => {
                                      setOpponent(opponents.find((opponent) => opponent.id === event.target.value) as TeamSummary);
                                  }}
                                >
                                    {
                                        // Sort opponents alphabetically by name before mapping
                                        [...opponents]
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((opponent, index) => (
                                                <MenuItem key={opponent.id} value={opponent.id}>
                                                    {opponent.name}
                                                </MenuItem>
                                            ))
                                    }
                                </Select>
                            </FormControl></Tooltip>
                        </Grid>
                          <Grid item xs={1} style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-evenly', alignItems: 'flex-end', alignContent:'flex-end'}}>
                          <Tooltip title={!competition ? "Choose a competition before adding opponents" : "Add Opponent team"} >
                              <IconButton size="medium" className={classes.addButton} 
                                onClick={() => setCurrentPage(navPage.addOpponent)}
                                disabled={!competition} >
                                  <PlaylistAddIcon fontSize="medium" />
                            </IconButton>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={5} className={classes.grid2}>
                            {formErrors.formation && <ErrorText>{formErrors.formation}</ErrorText>}
                            <FormControl fullWidth className={classes.formControl} style={{ margin: '0px 0px 0px 10px' }}>
                                <InputLabel id="demo-simple-select-label">Formation</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label = "Formation"
                                    onChange={(event) => setFormation(event.target.value as string)}
                                    value={formation}
                                >
                                    {
                                        formations.map((formation) => {
                                            return <MenuItem key={formation} value={formation}>{formation}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1}>
                        </Grid>
                        <Grid item xs={5} >
                            <TextField
                                label="Video Link (VEO, Youtube, etc)"
                                variant="outlined"
                                value={videoLink}
                                onChange={(e) => setVideoLink(e.target.value)}
                                error={!!formErrors.videoLink}
                                helperText={formErrors.videoLink}
                                style={{ margin: '20px 0px 10px 10px', width:'100%' }}
                                className={classes.formControl}
                            />
                        </Grid>              
                        
                        <Grid item xs={12}>
                          <Button
                            className={classes.bottomButton}
                            style={{ width: '100%', alignItems: 'center' }}
                            onClick={() => {
                              if (validateInput()) { // Assuming validateInput returns true if inputs are valid
                                setCurrentPage(navPage.formationPage);
                              }
                            }}
                          >
                            NEXT
                          </Button>
                        </Grid>
                    </Grid>
                    </FormContainer>);
            case 2:
                return (
                <FormContainer>
                <Grid container spacing={1}>
                  <Grid container spacing={2} style={{ padding: '20px' }}>
                    <Grid item xs={6}>
                      <TextField
                        label="Name (e.g Premier League)"
                        variant="outlined"
                        fullWidth
                        value={competitionName}
                        onChange={(e) => setCompetitionName(e.target.value)}
                        inputProps={{ maxLength: 50 }}
                        className={classes.formControlText}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <TextField
                        label="Competition Code (e.g PL)"
                        variant="outlined"
                        fullWidth
                        value={competitionCode}
                        onChange={(e) => setCompetitionCode(e.target.value)}
                        inputProps={{ maxLength: 5 }}
                        className={classes.formControlText}
                      />
                    </Grid>

                    <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel>Type</InputLabel>
                      <Select value={type} onChange={(e) => setType(e.target.value as string)}>
                          <MenuItem value="Club">Club</MenuItem>
                          <MenuItem value="National">National</MenuItem>
                      </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Gender</InputLabel>
                        <Select value={gender} onChange={(e) => setGender(e.target.value as string)}>
                          <MenuItem value="MALE">MALE</MenuItem>
                          <MenuItem value="FEMALE">FEMALE</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Country</InputLabel>
                        <Select value={country} onChange={(e) => setCountry(e.target.value as string)}>
                          <MenuItem value="">
                            <em>Select a country</em>
                          </MenuItem>
                          {COUNTRIES.map((country) => (
                            <MenuItem key={country} value={country}>
                              {country}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Category</InputLabel>
                        <Select value={category} onChange={(e) => setCategory(e.target.value as string)}>
                          {categories.map((cat) => (
                            <MenuItem key={cat} value={cat}>
                              {cat}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel>Division Level (1-3)</InputLabel>
                        <Select
                          value={divisionLevel}
                          onChange={(e) => setDivisionLevel(e.target.value as number)}
                        >
                          <MenuItem value={1}>1</MenuItem>
                          <MenuItem value={2}>2</MenuItem>
                          <MenuItem value={3}>3</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} style={{ marginTop: '20px', fontSize:'1rem'}}>
                      <TextField 
                        label="Match Duration (40-90)"
                        variant="outlined"
                        fullWidth
                        type="number"
                        value={matchDuration}
                        onChange={(e) => setMatchDuration(Number(e.target.value))}
                        inputProps={{ min: 40, max: 90 }}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        label="Season Start Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={start_date}
                        onChange={(e) => setSeasonStart(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.formControlText}
                      />
                    </Grid>

                    {/* Season End Date */}
                    <Grid item xs={6}>
                      <TextField
                        label="Season End Date"
                        type="date"
                        variant="outlined"
                        fullWidth
                        value={end_date}
                        onChange={(e) => setSeasonEnd(e.target.value)}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.formControlText}
                      />
                    </Grid>

                    <Grid item xs={12}>
                    <Button className={classes.bottomButtonCancel} style={{width:'50%'}} onClick={() => setCurrentPage(navPage.createGame)}>Cancel</Button>
                      <Button className={classes.bottomButton} style={{width:'50%'}} onClick={handleSubmitAddCompetition} >
                        Submit
                      </Button>
                    </Grid>
                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                      <Alert onClose={() => setOpenSnackbar(false)} severity="info">
                        {snackbarMessage}
                      </Alert>
                    </Snackbar>
                  </Grid>
                </Grid>
                </FormContainer>
            );
            case 3:
            return (
              <FormContainer>
                <Grid container spacing={2} style={{margin:'5px 10px 5px 10px', width:'100%', alignItems:'center',justifyContent: 'center', textAlign: 'center' }}>
                    <Grid item xs={12}>
                        <TextField
                        fullWidth
                        label="Opponent Team Name (e.g Real Madrid)"
                        value={opponentName}
                        onChange={(e) => setOpponentName(e.target.value)}
                        error={Boolean(formErrorsOpp.opponentName)}
                        helperText={formErrorsOpp.opponentName}
                        inputProps={{ maxLength: 30 }}
                        variant="outlined"
                        className={classes.formControlText}/>
                    </Grid>

                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                        <TextField
                        fullWidth
                        label="Opponent Team Code (e.g CRM)"
                        value={opponentCode}
                        onChange={(e) => setOpponentCode(e.target.value)}
                        error={Boolean(formErrorsOpp.opponentCode)}
                        helperText={formErrorsOpp.opponentCode}
                        inputProps={{ maxLength: 3 }}
                        variant="outlined"
                        className={classes.formControlText}/>
                    </Grid>

                    {/* Cancel Button */}
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Button className={classes.bottomButtonCancel} style={{width:'50%'}} onClick={() => setCurrentPage(navPage.createGame)}>Cancel</Button>
                        <Button className={classes.bottomButton} style={{width:'50%'}} onClick={handleOpponentSubmit} >
                          Submit
                        </Button>
                      </Grid>
                    

                    {/* Snackbar for notifications */}
                      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={() => setOpenSnackbar(false)}>
                          <Alert onClose={() => setOpenSnackbar(false)} severity="info">
                          {snackbarMessage}
                          </Alert>
                      </Snackbar>
                </Grid>
                </FormContainer>
                  );
            case 4:
            return (
              <FormContainer>
                <FieldPositions formation={formation} setFormation={setFormation} setCurrentPage = {setCurrentPage} players={players} submitGame={submitGame} dispatch={dispatch} teamId={teamId} season={season}/>
              </FormContainer>
                  );
            case 5: {
              return (
                <>
                <CreatePlayerModal setIsCreateGameComp={setIsCreateGameComp} player={newPlayer} calledFromGameModal={true} setCurrentPage={setCurrentPage}/>
            </>
                    );
            }
            case 6: {
              return(
                <FormContainer>
                      <Loading>
                       {loading ? (<LoadingLogo src={logo} alt="SportsAI_Logo" />) :(<></>)}
                        <MessageText>{messageMatchCreated}</MessageText>
                      </Loading>
              </FormContainer>)
            }
                }
    }

    return (
        <ModalWrapper>
         <ModalTopbar 
            Action={
                currentPage === navPage.createGame ? "CREATE A GAME" :
                currentPage === navPage.createCompetition ? "CREATE COMPETITION" :
                currentPage === navPage.addOpponent ? "ADD OPPONENT" :
                currentPage === navPage.formationPage ? "STARTING FORMATION: DRAG / CLICK ON PLAYERS TO FILL YOUR STARTERS " :
                currentPage === navPage.addPlayer ? "Add a player":
                currentPage === navPage.creatingGame ? "CREATING GAME...":
                "" 
            }
            onClose={() => setIsCreateGameComp(false)} 
        />
        
        {renderPageContent()}
        </ModalWrapper>
    )
}

const FormContainer = styled.div`
  display: flex;
  overflow: 'hidden', // Prevent scroll bars
  align-items: center;
  justify-content: space-between;
  max-height: 100vh,  // Keep the modal within the viewport
  max-width: 100vw,   // Adjust width to avoid overflow
  boxSizing: 'border-box',
`;
const Loading = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;  /* This centers the content horizontally */
  width: 100%;
  text-align: center;   /* This centers the text */
`;

const LoadingLogo = styled.img`
  width: 15rem;
  margin: 50px;
  animation: blinkAndGrow 2s infinite ease;
`;
const MessageText = styled.div`
  color: ${DARK_BLUE};
  font-size: 1.2rem;
  margin: 10px;
  font-family: Karla Bold;
`;
const ErrorText = styled.div`
  color: red;
  font-size: 0.8rem;
  margin-top: 5px;
`;

const ModalWrapper = styled.div`
overflow: 'hidden', // Prevent scroll bars
top: 60%;
left: 5%;
max-width: 100%;
max-height: 95%;
min-height: 150px; 
background-color: ${WHITE};
border: 10px solid ${WHITE};
border-radius: 75px;
z-index: 1000;
`;

const FieldContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(8, 1fr);
  grid-template-columns: repeat(10, 1fr);
  background-color: #76a839;
  height: 100%;
  position: relative;
`;

const PositionSpot = styled.div`
  border: 1px dashed #fff;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;