import { Grid } from "@material-ui/core";
import { DARK_BLUE, LIGHT_BLUE } from "../../assets/colors";
import styled from "styled-components";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@material-ui/core/IconButton';

const ModalTopbar = ({Action, onClose, showBar=true}) => {
    const conditionalStyles = !showBar ? { padding: '0.5rem 0rem 0rem 0rem' } : {};

    return (
        <Grid container spacing={0} alignItems="center" style={{ justifyContent: "space-between", display: "flex" }}>
            <Title>
                {Action}
            </Title>
            <Exit onClick={onClose}>
                <IconButton style={conditionalStyles}>
                    <CloseIcon />
                </IconButton>
            </Exit>
            {showBar && <HorizontalLine />}
        </Grid>
    );
}

const HorizontalLine = styled.div`
  background-color: ${LIGHT_BLUE};
  height: 2px;
  width: 100%;
  margin: 0.5rem 0rem 0rem 0rem;
`;

const Exit = styled.div`
  color: ${DARK_BLUE};
  display: flex;
`;

const Title = styled.div`
  color: ${DARK_BLUE};
  padding: 2rem 0rem 0.5rem 1rem;
  font-size: 1rem;
  font-family: Antique Olive Nord;
  display: flex;
`;

export default ModalTopbar;