import { Pie, PieChart, ResponsiveContainer, Cell, Tooltip as RechartsTooltip } from "recharts";
import styled from "styled-components";
import { DARK_BLUE, GREY, LIGHT_GREY, RED_TO_GREEN_GRADIENT, WHITE } from "../../../assets/colors";
import { IconButton, Tooltip as MaterialTooltip, TooltipProps, tooltipClasses } from "@mui/material"; // Import Material-UI Tooltip for the icon
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TooltipCustom from "../../../shared-components/tooltip";


const PlayerGradePie = ({ total, onClick = () => {} }) => {
  let isDataValid = true;
  if (!total) isDataValid = false;
  const data = [
    { name: "Filled", value: Math.floor(total) },
    { name: "Unfilled", value: Math.floor(100 - total) },
  ];

  const getColor = () =>
    RED_TO_GREEN_GRADIENT[
      Math.floor(
        Math.min(
          (total / 100) * (RED_TO_GREEN_GRADIENT.length - 1),
          RED_TO_GREEN_GRADIENT.length - 1
        )
      )
    ];

  return (
    <Wrapper onClick={() => onClick()}>
      {isDataValid ? (
        <>
          <Title style={{ marginTop: "1rem" }}>
            <span>RATING</span>
            <TooltipCustom description=
              {"Player ratings are calculated using a performance-based formula that considers a specified number of games choosen below and all our data points with different weights applied depending on the player's position in the game."
              }
            >
            </TooltipCustom>
          </Title>

          <PieWrapper>
            <ResponsiveContainer width="99%" height={100}>
              <PieChart>
                <Pie
                  data={data}
                  startAngle={90}
                  endAngle={-270}
                  dataKey="value"
                  innerRadius={30}
                  outerRadius={40}
                >
                  <Cell fill={getColor()}></Cell>
                  <Cell fill={LIGHT_GREY}></Cell>
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <Percentage style={{ color: getColor() }}>{`${Math.floor(total)}`}</Percentage>
          </PieWrapper>
        </>
      ) : (
        <>
          <Title style={{ marginTop: "1rem" }}>{`RATING`}</Title>
          <NoDataWrapper>NA</NoDataWrapper>
        </>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 0 0.5rem;
  background-color: white;
  min-height: 150px;
  height: calc(100% - 4px);
  b {
    text-transform: uppercase;
  }
`;

const Title = styled.div`
  text-transform: capitalize;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  b {
    margin-left: 5px;
    color: ${GREY};
  }
`;

const QuestionMark = styled.span`
  margin-left: 0.5rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: ${GREY};
`;

const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PieWrapper = styled.div`
  width: 150px;
  height: 100px;
  align-items: center;
  justify-content: center;
`;

const Percentage = styled.div`
  font-size: 1.2rem;
  width: 100%;
  text-align: center;
  margin-top: -3.9rem;
`;

export default PlayerGradePie;
