export const soccerFormations: string[] = [
    "4-1-4-1",
    "4-1-2-1-2",
    "4-2-3-1",
    "4-2-2-2",
    "4-3-3",
    "4-3-1-2",
    "4-3-2-1",
    "4-4-2",
    "4-5-1",
    "3-4-3",
    "3-4-2-1",
    "3-4-1-2",
    "3-5-2",
    "5-3-2",
  ];