import styled from "styled-components";
import { DARK_BLUE, GREY, GREY2, LIGHT_GREY, RED } from "../../../assets/colors";

interface ProgressBarProps {
    teamAStat: number;
    teamBStat: number;
    title: string;
    scale: number;
    isPercent?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ title, teamAStat, teamBStat,scale, isPercent=false}) => {
    var teamAStatValue = teamAStat;
    var teamBStatValue = teamBStat;

    if (!isPercent) {
        teamAStatValue = (teamAStat/scale) * 100;
        teamBStatValue = (teamBStat/scale) * 100;
    }
    return (
        <div>
            <SummaryStatsTitle>{title}</SummaryStatsTitle>
            <ProgressBarContainer>
                <LabelLeft>{teamAStat} {isPercent && '%'}</LabelLeft>
                <ProgressBarWrapper>
                    <ProgressBarFill width={`${teamAStatValue/2}%`} backgroundColor={DARK_BLUE} position="left"/>
                    <ProgressBarFill width={`${teamBStatValue/2}%`} backgroundColor={RED} position="right"/>
                </ProgressBarWrapper>
                <LabelRight>{teamBStat} {isPercent && '%'}</LabelRight>
            </ProgressBarContainer>
        </div>
    );
};

const SummaryStatsTitle = styled.div`
    margin-bottom: 0.5rem;
    align-items: center;
    text-align: center;
    padding: 0.5rem;
    color: ${GREY2};
`;

const ProgressBarContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
`;

const ProgressBarWrapper = styled.div`
    position: relative;
    height: 10px;
    width: 100%;
    background-color: ${LIGHT_GREY}
    border-radius: 5px;
    overflow: hidden;
    
`;

const ProgressBarFill = styled.div<{ width: string; backgroundColor: string; position: string }>`
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 20px;
    ${({ position }) => position === 'left' && 'left: 49.9%; transform: translateX(-99.8%);'}
    ${({ position }) => position === 'right' && 'right: 49.9%; transform: translateX(99.8%);'}
    background-color: ${({ backgroundColor }) => backgroundColor}; /* Use the passed backgroundColor prop */
    width: ${({ width }) => width};
    
`;

const LabelLeft = styled.div`
    font-size: 15px;
    padding: 0.5rem;
    color: ${GREY};
    text-align: center; /* Center the text inside LabelLeft */
    width: 60px; /* Ensure the left label has enough space */
`;

const LabelRight = styled.div`
    font-size: 15px;
    padding: 0.5rem;
    color: ${GREY};
    text-align: center; /* Center the text inside LabelRight */
    width: 60px; /* Ensure the right label has enough space */
`;
export default ProgressBar;
