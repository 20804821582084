import { Button, Grid, makeStyles, MenuItem, Select } from "@material-ui/core";
import { useEffect, useState } from "react";
import styled from "styled-components";
import PlayerCard from "../player-card/player-card";
import SectionHeader from "../section-header/section-header";
import store, { useAppDispatch } from "../../../app/store";
import { changePage, Page } from "../../../slices/navigationSlice";
import { KeyboardArrowDown, KeyboardArrowRight } from "@material-ui/icons";
import { Stat, PlayerStats } from "../../../models/player";
import { useSelector } from "react-redux";
import {
  getPlayersStats,
  selectPlayersStats,
  setPlayersStats,
} from "../../../slices/playerSlice";
import { selectTeamId } from "../../../slices/teamSlice";
import { selectGamesSummary } from "../../../slices/gamesSlice";
import { DARK_BLUE, GREY, LIGHT_GREY1, WHITE } from "../../../assets/colors";
import { selectSeason } from "../../../slices/userSlice";
import TooltipCustom from "../../../shared-components/tooltip";

const sizeFilterOptions = new Map<string, number>();

const typeFilterOptions = [
  "All",
  "Forward",
  "Midfielder",
  "Defender",
  "Goalkeeper",
];

const useStyles = makeStyles(() => ({
  tabButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 5px",
    fontWeight: "bold",
  },
  selected: {
    fontWeight: "bold",
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
  showAllButton: {
    backgroundColor: "transparent",
    color: DARK_BLUE,
    padding: "3px 8px",
    fontWeight: "bold",
    textTransform: "none",
  },
  arrowBtnSelected: {
    transform: "rotate(180deg)",
  },
}));

const PlayersEvolution = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [sizeFilter, setSizeFilter] = useState("Last Game");
  const [positionFilter, setPositionFilter] = useState("All");
  const [expandBestPerformances, setExpandBestPerformances] = useState(false);
  const [expandWarnings, setExpandWarnings] = useState(false);
  const teamId = useSelector(selectTeamId);
  const games = useSelector(selectGamesSummary);
  const season: number | undefined = useSelector(selectSeason);
  let players: PlayerStats[] = useSelector(selectPlayersStats);

  const maxStatNumber = 16;

  sizeFilterOptions.set("Last Game", 1);
  sizeFilterOptions.set("Last 3", 3);
  sizeFilterOptions.set("Last 5", 5);
  sizeFilterOptions.set("Last 10", 10);
  // sizeFilterOptions.set("Season", 30); Add when fixing multiple season

  useEffect(() => {
    const filterSize = sizeFilterOptions.get(sizeFilter);
    if (teamId) {
      dispatch(setPlayersStats([]));
      dispatch(
        getPlayersStats({
          teamId: teamId,
          filterSize: filterSize ? filterSize : 0,
          season:season
        })
      );
    }
  }, [sizeFilter, dispatch, teamId, season]);

  const sizeButtons = () => {
    const buttons: JSX.Element[] = [];

    for (const [buttonName, value] of sizeFilterOptions) {
      let isButtonActive = false;

      if (buttonName !== "Season") {
        isButtonActive = value * 2 <= games.length;
      }

      buttons.push(
        <Button
          disabled={!isButtonActive}
          style={{ padding: "2px 10px", margin: "0px 5px" }}
          key={buttonName}
          className={
            sizeFilter === buttonName ? classes.selected : classes.tabButton
          }
          onClick={() => {
            setSizeFilter(buttonName);
          }}
        >
          {buttonName}
        </Button>
      );
    }

    return buttons;
  };

  const playersFiltered = () => {
    return positionFilter !== "All"
      ? players.filter(
          (player) =>
            player.position.toLowerCase() === positionFilter.toLowerCase()
        )
      : players;
  };

  const sortedStats = (type: string) => {
    let stats: Stat[] = [];

    for (const player of playersFiltered()) {
      stats.push(...player.stats);
    }

    stats.sort((a, b) => Math.abs(a.performance) - Math.abs(b.performance));

    return type === "success"
      ? stats.filter((stat) => !stat.isWarning)
      : stats.reverse().filter((stat) => stat.isWarning);
  };

  const toggleBestPerformanceGridHeight = () => {
    const gridItem = document.getElementById("bestPerformanceGrid");

    if (gridItem) {
      if (!expandBestPerformances) {
        gridItem.style.height = "100%";
      } else {
        gridItem.style.height = "175px";
      }
    }

    setExpandBestPerformances(!expandBestPerformances);
  };

  const toggleWarningGridHeight = () => {
    const gridItem = document.getElementById("warningGrid");

    if (gridItem) {
      if (!expandWarnings) {
        gridItem.style.height = "100%";
      } else {
        gridItem.style.height = "175px";
      }
    }

    setExpandWarnings(!expandWarnings);
  };

  return (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeButtons()}</SizeFilterWrapper>
        <SectionHeaderBis >Performances evolution
          <TooltipCustom description={"Performance evolution tracks the biggest variations (good or bad) of players over a selected time period."} />
        </SectionHeaderBis>
        <TypeFilterWrapper>
          Position:
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px" }}
            value={positionFilter}
            fullWidth
            onChange={(event) => {
              setPositionFilter(event.target.value as string);
            }}
          >
            {typeFilterOptions.map((value, index) => {
              return (
                <MenuItem key={`MenuItem${index}`} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
        </TypeFilterWrapper>
      </FiltersWrapper>
      <SectionHeader title={"Best Performances"} />
      {sortedStats("success").length > 0 ? (
        <>
          <Grid
            id="bestPerformanceGrid"
            container
            spacing={1}
            style={{ height: "175px", overflowY: "hidden" }}
          >
            {sortedStats("success").map((stat: Stat, index) => {
              return index < maxStatNumber ? <PlayerCard stat={stat} /> : <></>;
            })}
          </Grid>
          {store.getState().page.page === Page.Home ? (
            <ButtonWrapper>
              <Button
                className={classes.showAllButton}
                onClick={() => {
                  dispatch(changePage(Page.Players));
                }}
              >
                {`All Players`}
                <KeyboardArrowRight />
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper style={{ justifyContent: "center" }}>
              <Button
                className={classes.showAllButton}
                onClick={() => toggleBestPerformanceGridHeight()}
              >
                {!expandBestPerformances ? "Show all" : "Hide"}
                <KeyboardArrowDown
                  className={
                    expandBestPerformances ? classes.arrowBtnSelected : ""
                  }
                />
              </Button>
            </ButtonWrapper>
          )}
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
      <SectionHeader title={"Warnings"} />
      {sortedStats("warning").length > 0 ? (
        <>
          <Grid
            id="warningGrid"
            container
            spacing={1}
            style={{ height: "175px", overflowY: "hidden" }}
          >
            {sortedStats("warning").map((stat: Stat, index) => {
              return index < maxStatNumber ? <PlayerCard stat={stat} /> : <></>;
            })}
          </Grid>
          {store.getState().page.page === Page.Home ? (
            <ButtonWrapper>
              <Button
                className={classes.showAllButton}
                onClick={() => {
                  dispatch(changePage(Page.Players));
                }}
              >
                {`All Players`}
                <KeyboardArrowRight />
              </Button>
            </ButtonWrapper>
          ) : (
            <ButtonWrapper style={{ justifyContent: "center" }}>
              <Button
                className={classes.showAllButton}
                onClick={() => toggleWarningGridHeight()}
              >
                {!expandWarnings ? "Show all" : "Hide"}
                <KeyboardArrowDown
                  className={expandWarnings ? classes.arrowBtnSelected : ""}
                />
              </Button>
            </ButtonWrapper>
          )}
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
const SectionHeaderBis = styled.div`
  padding-right: 10%;
  width: 100%;
  margin: 0 10px 0 0 ;
  text-align: center; 
  font: 1.5rem 'Antique Olive Nord';
  color: ${DARK_BLUE};
  background-color: ${LIGHT_GREY1};
  text-transform: uppercase;
`;
const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  height: 175px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: auto;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const TypeFilterWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 30px 5px 10px;
  }
`;
const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  margin: 2rem 0rem 1rem;
`;
export default PlayersEvolution;
