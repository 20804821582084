import { Button, Grid } from "@material-ui/core";
import { useState } from "react";
import styled from "styled-components";
import {
  LIGHT_GREY1,
  LIGHT_GREY2,
} from "../../assets/colors";
import PlayersEvolution from "../../components/home-page/players-evolution/players-evolution";
import SquadOverview from "../../components/player-page/squad-page/squad-page";
import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

enum StatPage {
  Overview,
  Squad,
}

const Players = () => <LoadingWrapper comp={<PlayersBase/>}/>

const PlayersBase = () => {
  const classes = sportsAiGlobalStyles();
  const [statPage, setStatPage] = useState(StatPage.Squad);

  return (
    <Wrapper>
      <HeaderWrapper>
        <TopBar name={"Players"}/>
        <Grid container>
        <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Squad
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Squad);
              }}
            >
              Squad
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              className={
                statPage === StatPage.Overview
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => {
                setStatPage(StatPage.Overview);
              }}
            >
              Overview
            </Button>
          </Grid>
         
        </Grid>
      </HeaderWrapper>
      {statPage === StatPage.Overview ? (
        <PlayersEvolution />
      ) : (
        <div style={{ padding: "2rem" }}>
          <SquadOverview />
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  background-color: ${LIGHT_GREY1};
  font-family: Karla Bold;
`;
const HeaderWrapper = styled.div`
  width: 100%;
  background-color: white;
`;

export default Players;
