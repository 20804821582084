import { useState } from "react";
import {
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Legend,
  Line,
  Label,
  LineChart,
} from "recharts";
import { Button, makeStyles } from "@material-ui/core";
import styled from "styled-components";
import {
  DARK_BLUE,
  GREY,
  LIGHT_BLUE,
  LIGHT_GREY,
  LIGHT_RED1,
  RED,
  GREEN,
  YELLOW,
  WHITE,
  LIGHT_RED,
  CYAN,
} from "../../../assets/colors";

const useStyles = makeStyles(() => ({
  tabButton: {
    backgroundColor: "white",
    color: DARK_BLUE,
    padding: "3px 15px",
    margin: "0px 10px 40px 10px",
    fontFamily: "Karla Bold",
    fontWeight: "bold",
  },
  tabButtonSelected: {
    backgroundColor: DARK_BLUE,
    color: "white",
    padding: "3px 15px",
    margin: "0px 10px 40px 10px",
    fontFamily: "Karla Bold",
    "&:hover": {
      backgroundColor: DARK_BLUE,
      opacity: "80%",
      color: WHITE,
    },
  },
}));

const CustomizedDot = (props) => {
  const { cx, cy, payload, hoveredLine } = props;

  const textZIndex = hoveredLine === "Result" ? 10 : 1;

  return (
    <>
      <image
        x={cx - 12}
        y={cy - 12}
        width={24}
        height={24}
        href={payload.opponentLogo}
        style={{ borderRadius: "50%" }}
      />
      <text
        x={cx}
        y={cy + 30}
        fill={DARK_BLUE}
        fontSize={12}
        fontWeight="bold"
        textAnchor="middle"
        style={{ zIndex: textZIndex }} 
      >
        {`${payload.teamScore}-${payload.opponentScore}`}
      </text>
    </>
  );
};

const XGDot = (props) => {
  const { cx, cy, value, dataKey, hoveredLine } = props;

  // Determine the text color based on the dataKey
  const getColor = () => {
    switch (dataKey) {
      case "xG":
        return CYAN; // Color for xG
      case "opponentXG":
        return LIGHT_RED; // Color for opponent xG
      default:
        return DARK_BLUE; // Default color
    }
  };

  const isHovered = hoveredLine === dataKey; // Check if the current line is hovered

  return (
    <>
      <text
        x={cx}
        y={cy + 30} // Adjusted to place text below the circle
        fill={getColor()} // Dynamically set the color
        fontSize={isHovered ? 24 : 12} // Increase font size when hovered
        fontWeight="bold"
        textAnchor="middle"
        style={{ zIndex: isHovered ? 10 : 1 }} 
      >
        {value}
      </text>
    </>
  );
};


const Scores = ({ gamesPlayed }) => {
  let isDataValid = true;
  let formattedGames;
  if (!gamesPlayed) {
    isDataValid = false;
    gamesPlayed = [];
  } else {
    formattedGames = [...gamesPlayed].reverse().map((game) => ({
      ...game,
      result:
        game.teamScore > game.opponentScore
          ? "Win"
          : game.teamScore === game.opponentScore
          ? "Tie"
          : "Loss",
      yValue:
        game.teamScore > game.opponentScore
          ? 3
          : game.teamScore === game.opponentScore
          ? 2
          : 1,
    }));
  }

  const [graphDataAmount, setGraphDataAmount] = useState(5);
  const [hoveredLine, setHoveredLine] = useState<string | null>(null);

  const classes = useStyles();

  const formatData = (size) => {
    if (size < formattedGames.length) {
      return formattedGames.slice(formattedGames.length - size, formattedGames.length);
    }
    return formattedGames;
  };

  const getLineStrokeWidth = (dataKey) => {
    if (hoveredLine === dataKey) {
      return 4;
    }
    return 2;
  };

  const getLineZIndex = (dataKey) => {
    if (hoveredLine === dataKey) {
      return 10;
    }
    return 1;
  };

  const labelSize = hoveredLine ? 24 : 12; // Adjust label size on hover

  const getLineOpacity = (dataKey) => {
    if (hoveredLine && hoveredLine !== dataKey) {
      return 0.40; // Reduce opacity for non-hovered lines
    }
    return 1; // Full opacity for the hovered line or if no line is hovered
  };

  return (
    <Wrapper>
      {isDataValid ? (
        <>
          <div style={{ display: "flex" }}>
            <Button
              className={
                graphDataAmount === 5
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(5)}
            >
              Last 5
            </Button>
            <Button
              className={
                graphDataAmount === 10
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(10)}
            >
              Last 10
            </Button>
            <Button
              className={
                graphDataAmount === Infinity
                  ? classes.tabButtonSelected
                  : classes.tabButton
              }
              onClick={() => setGraphDataAmount(Infinity)}
            >
              Season
            </Button>
          </div>
          <ResponsiveContainer width="99%" height={300}>
            <LineChart
              data={formatData(graphDataAmount)}
              margin={{
                top: 30,
                right: 30,
                left: 0,
                bottom: 30,
              }}
              onMouseLeave={() => setHoveredLine(null)}
            >
              <CartesianGrid vertical={false} horizontal={true} stroke={LIGHT_BLUE} />
              <YAxis domain={[0, 3]} ticks={[1, 2, 3]} tick={false} axisLine={false} />
              <XAxis axisLine={false} tickLine={false} tick={false} />
              <Legend
                verticalAlign="top"
                height={60}
                payload={[
                  { value: "xG", type: "line", color: CYAN },
                  { value: "OPPONENT xG", type: "line", color: LIGHT_RED },
                ]}
              />
              <Line
                type="monotone"
                dataKey="yValue"
                stroke={DARK_BLUE}
                strokeWidth={getLineStrokeWidth("Result")}
                fill={WHITE}
                dot={<CustomizedDot />}
                activeDot={{ stroke: LIGHT_RED, strokeWidth: 2, r: 8 }}
                isAnimationActive={false}
                onMouseEnter={() => setHoveredLine("Result")}
                style={{ zIndex: getLineZIndex("Result"), opacity: getLineOpacity("Result") }} // Apply opacity
              >
                {(props) => {
                  return (
                    <text
                      x={props.cx}
                      y={props.cy - 10}
                      fill={DARK_BLUE}
                      fontSize={labelSize}
                      fontWeight="bold"
                      textAnchor="middle"
                    >
                      RESULT
                    </text>
                  );
                }}
              </Line>
              <Line
                type="monotone"
                dataKey="xG"
                stroke={CYAN}
                strokeWidth={getLineStrokeWidth("xG")}
                fill="none"
                dot={<XGDot />}
                isAnimationActive={false}
                onMouseEnter={() => setHoveredLine("xG")}
                style={{ zIndex: getLineZIndex("xG"), opacity: getLineOpacity("xG") }} // Apply opacity
              >
                {(props) => {
                  return (
                    <text
                      x={props.cx}
                      y={props.cy - 10}
                      fill={CYAN}
                      fontSize={labelSize}
                      fontWeight="bold"
                      textAnchor="middle"
                    >
                      xG
                    </text>
                  );
                }}
              </Line>
              <Line
                type="monotone"
                dataKey="opponentXG"
                stroke={LIGHT_RED}
                strokeWidth={getLineStrokeWidth("opponentXG")}
                fill="none"
                dot={<XGDot />}
                isAnimationActive={false}
                onMouseEnter={() => setHoveredLine("opponentXG")}
                style={{ zIndex: getLineZIndex("opponentXG"), opacity: getLineOpacity("opponentXG") }} // Apply opacity
              >
                {(props) => {
                  return (
                    <text
                      x={props.cx}
                      y={props.cy - 10}
                      fill={LIGHT_RED}
                      fontSize={labelSize}
                      fontWeight="bold"
                      textAnchor="middle"
                    >
                      OPPONENT xG
                    </text>
                  );
                }}
              </Line>
            </LineChart>
          </ResponsiveContainer>
        </>
      ) : (
        <NoDataWrapper>No Data</NoDataWrapper>
      )}
    </Wrapper>
  );
};


const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  background-color: white;
  margin-bottom: 2rem;
  padding-top: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;

const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
`;

const Title = styled.h1`
  font-size: 1.8rem;
  font-weight: bold;
  color: ${DARK_BLUE};
`;

export default Scores;
