import { useSelector } from "react-redux";
import { DARK_BLUE, LIGHT_GREY, LIGHT_RED } from "../../../assets/colors";
import { goalTypeCounter } from "../../stats-base/field-zone-events/event-position-tooltip";
import FieldZoneEvent from "../../stats-base/field-zone-events/field-zone-events";
import styled from "styled-components";
import Goal from "../../../models/goalScored";
import { selectCurrentPlayer } from "../../../slices/playerSlice";
import { Player } from "../../../models/player";

type goaltypesType = "" | "s" | "g";

const getEvents = (playerStats: Player | undefined) => {
    let events: Goal[][];
    let indicatorIcons: {title: string, value: number, color: string, code: goaltypesType}[] = []
    if (!playerStats) {
        return {events: [], indicatorIcons: []}
    } else if (!playerStats.eventsPositions) {
        return {events: [], indicatorIcons: []}
    }
    const unpackedGoals = playerStats.eventsPositions
    events = [unpackedGoals];
    const goalTypeCounts = goalTypeCounter(events);
    indicatorIcons = [
        {title: 'SAVES', value: goalTypeCounts.s, color: DARK_BLUE, code: 's'},
        {title: 'CONCEDED GOALS', value: goalTypeCounts.g, color: LIGHT_RED, code: 'g'},
    ]
    return {events, indicatorIcons}
}

export interface ZoneEventPositionConfig {
    title: string;
    eventType: any; 
    goalsScored: any; 
    filter: {title: string, games: string}[],
    eventTypes: {title: string, value: number, color: string, code: goaltypesType}[]
}

const FacedShotsPosition = () => {
    const player: Player | undefined = useSelector(selectCurrentPlayer);
    const config = getEvents(player);
    return (
        <Wrapper>
            <Title>
            Faced Shots
            </Title>
            <FieldZoneEvent evntsPositions={config.events} indicatorIcons={config.indicatorIcons}/>
        </Wrapper>
    )
};

const Title = styled.div`
  text-transform: uppercase;
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
`;

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 2rem 0rem;
  margin-bottom: 2rem;
  border: 2px solid ${LIGHT_GREY};
  border-radius: 8px;
`;

export default FacedShotsPosition;
