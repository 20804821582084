import React, { useEffect, useRef } from 'react';

interface SoccerFieldProps {
  borderRadius?: number; // Border radius for the rectangle
  lineColor?: string; // Color for the lines
}

const SoccerField: React.FC<SoccerFieldProps> = ({
  borderRadius = 0,
  lineColor = 'white',
}) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  const drawField = (ctx: CanvasRenderingContext2D, width: number, height: number) => {
    // Clear the canvas
    ctx.clearRect(0, 0, width, height);

    // Draw the main rectangle (soccer field)
    ctx.fillStyle = 'white'; // Fill color for the field
    ctx.lineWidth = 2;
    ctx.strokeStyle = lineColor; // Line color for the field
    ctx.beginPath();
    ctx.roundRect(0, 0, width, height, borderRadius);
    ctx.fill();
    ctx.stroke();

    // Draw the center line
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(width / 2, 0);
    ctx.lineTo(width / 2, height);
    ctx.stroke();

    // Draw the center ellipse
    const centerEllipseRadiusX = width * 0.07; // Horizontal radius
    const centerEllipseRadiusY = height * 0.15; // Vertical radius
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.ellipse(width / 2, height / 2, centerEllipseRadiusX, centerEllipseRadiusY, 0, 0, Math.PI * 2);
    ctx.stroke();


    // Draw rectangles at each vertical edge (left and right sides)
    const rectWidth = width * 0.07; // Width of the rectangle
    const rectHeight = height * 0.4; // Height of the rectangle
    const leftX = 1; // Left rectangle position
    const rightX = width - rectWidth; // Right rectangle position

    // Draw the left rectangle (top, right, and bottom edges)
    const leftY = (height - rectHeight) / 2;
    ctx.lineWidth = 1;
    ctx.strokeStyle = lineColor;

    // Draw the top edge of the left rectangle
    ctx.beginPath();
    ctx.moveTo(leftX, leftY); // Start at the top left
    ctx.lineTo(leftX + rectWidth, leftY); // Draw to the top right
    ctx.stroke();

    // Draw the right edge of the left rectangle
    ctx.beginPath();
    ctx.moveTo(leftX + rectWidth, leftY); // Start at the top right
    ctx.lineTo(leftX + rectWidth, leftY + rectHeight); // Draw down to the bottom right
    ctx.stroke();

    // Draw the bottom edge of the left rectangle
    ctx.beginPath();
    ctx.moveTo(leftX, leftY + rectHeight); // Start at the bottom left
    ctx.lineTo(leftX + rectWidth, leftY + rectHeight); // Draw to the bottom right
    ctx.stroke();

    // Draw the right rectangle (top, bottom, and left edges)
    const rightY = (height - rectHeight) / 2;

    // Draw the top edge of the right rectangle
    ctx.beginPath();
    ctx.moveTo(rightX, rightY); // Start at the top left of the right rectangle
    ctx.lineTo(rightX + rectWidth, rightY); // Draw to the top right of the right rectangle
    ctx.stroke();

    // Draw the bottom edge of the right rectangle
    ctx.beginPath();
    ctx.moveTo(rightX, rightY + rectHeight); // Start at the bottom left of the right rectangle
    ctx.lineTo(rightX + rectWidth, rightY + rectHeight); // Draw to the bottom right of the right rectangle
    ctx.stroke();

    // Draw the left edge of the right rectangle
    ctx.beginPath();
    ctx.moveTo(rightX, rightY); // Start at the top left
    ctx.lineTo(rightX, rightY + rectHeight); // Draw down to the bottom left
    ctx.stroke();
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const width = canvas.width;
        const height = canvas.height;
        drawField(ctx, width, height);
      }
    }
  }, [borderRadius, lineColor]);

  return (
    <canvas
      ref={canvasRef}
      width={500} // Set the internal width in pixels
  height={300} // Set the internal height in pixels
      style={{ width: '100%', height: '100%' }}
    />
  );
};

export default SoccerField;
