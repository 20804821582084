import styled from "@emotion/styled";
import { searchBarStyles, sportsAiGlobalStyles } from "../../../shared/globalStyle";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY0, LIGHT_GREY1, LIGHT_GREY2, RED } from "../../../assets/colors";
import { useSelector } from "react-redux";
import { Search } from "@material-ui/icons";
import { Button, Dialog, Grid, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import {  useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { selectUserPlayerInfos } from "../../../slices/playerSlice";
import AddIcon from '@mui/icons-material/Add';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { CreatePlayerModal } from "./CreatePlayerModal";
import { PlayerInfo } from "../../../models/player";
import { DeletePlayerModal } from "./DeletePlayerModal";

const ProfileTeam = () => {
    const classes = sportsAiGlobalStyles();
    const searchBarClass = searchBarStyles();
    const players = useSelector(selectUserPlayerInfos);

    const [selectedPlayers, setSelectedPlayers] = useState(players);
    const [firstName, setFirstName] = useState<undefined | string>(undefined);
    const [isCreatePlayerComp, setIsCreatePlayerComp] = useState(false);
    const [isDeletePlayerComp, setIsDeletePlayerComp] = useState(false);
    const [selectedPlayer, setSelectedPlayer] = useState<PlayerInfo | undefined>(undefined);

    const applySearchFilter = (value: string) => {
        setSelectedPlayers(players.filter((player) => {
          return player.firstName.toLowerCase().includes(value.toLowerCase()) || player.lastName.toLowerCase().includes(value.toLowerCase());
        }));
    }

    useEffect(() => {
        if (firstName !== undefined) {
            setSelectedPlayers(players.filter((player) => {
                return player.firstName.toLowerCase().includes(firstName.toLowerCase()) || player.lastName.toLowerCase().includes(firstName.toLowerCase());
            }));
        }else{
            setSelectedPlayers(players);
        }
    }, [players]);

    return (
        <Wrapper>
            <Dialog 
                open={isCreatePlayerComp} 
                PaperProps={{
                    style: {
                        borderRadius: "1em",
                    },
                }}
                onClose={() => setIsCreatePlayerComp(false)}
            >
                <CreatePlayerModal setIsCreateGameComp={setIsCreatePlayerComp} player={selectedPlayer} calledFromGameModal={false} setCurrentPage={''}/>
            </Dialog>
            <Dialog 
                open={isDeletePlayerComp} 
                PaperProps={{
                    style: {
                        borderRadius: "1em",
                        width: "600px",
                        maxWidth: "none" 
                    },
                }}
                onClose={() => setIsDeletePlayerComp(false)}
            >
                <DeletePlayerModal setIsCreateGameComp={setIsDeletePlayerComp} player={selectedPlayer}/>
            </Dialog>
            <Grid container spacing={0}>
                <Grid item xs={10}>
                    <SearchWrapper>
                        <OutlinedInput
                            classes={searchBarClass}
                            style={{ borderRadius: "0.5rem", margin: "0rem 0rem 0rem 1rem", width: "98%" }}
                            placeholder="Search a player..."
                            onChange={(event) => applySearchFilter(event.target.value)}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search style={{ color: LIGHT_GREY }} />
                                </InputAdornment>
                            }
                        />
                    </SearchWrapper>
                </Grid>
                <Grid item xs={2}>
                    <AddUserButton
                        className={classes.fullBlueButton}
                        style={{ textTransform: "none"}}
                        onClick={() => setIsCreatePlayerComp(true)}
                    >
                    <AddIcon/> Add Player
                    </AddUserButton> 
                </Grid>
            </Grid>
            <Title>
                PLAYERS
            </Title>
            {selectedPlayers.map((player) => {
                return (
                    <ProfileEntry key={player.id}>
                        <ProfileIcon src={player.profile} />
                        <ProfileText>{player.firstName + ' ' + player.lastName}</ProfileText>
                        <ProfileIconWrapper>
                            {firstName !== undefined ? (
                                <>
                                    <OutlinedInput
                                        classes={searchBarClass}
                                        style={{ width: "10rem", height: "2.2rem", borderRadius: "0.5rem", padding: "0.5rem" }}
                                        value={firstName}
                                        onChange={(event) => setFirstName(event.target.value)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="clear input"
                                                    onClick={() => setFirstName('')}
                                                    edge="end"
                                                >
                                                    <CloseIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                </>
                            ) : (
                                <ProfileText>
                                    {player.email === null ? "No account" : player.email}
                                </ProfileText>
                            )}
                        </ProfileIconWrapper>
                        <ProfileText>
                            {player.position + " | #" + player.number} 
                        </ProfileText>
                        <UploadButtonWrapper>
                            <>
                                <UploadButton
                                    className={classes.fullBlueButton}
                                    style={{ textTransform: "none" }}
                                    onClick={() => {setSelectedPlayer(player); setIsCreatePlayerComp(true);}}
                                >
                                    <EditOutlinedIcon style={{ marginRight: '0.2rem' }} />
                                    Edit
                                </UploadButton>
                                <Button
                                    className={classes.transparantButton}
                                    style={{ border: `1px solid ${LIGHT_GREY0}`, color: RED,textTransform: "none", marginLeft: "0.5rem", width: "6rem", height: "2.2rem", borderRadius: "0.5rem" }}
                                    onClick={() => {setSelectedPlayer(player);setIsDeletePlayerComp(true);}}
                                >
                                    Delete
                                </Button>
                            </>
                        </UploadButtonWrapper>
                    </ProfileEntry>
                );
            })}
        </Wrapper>
    );
};

const Wrapper = styled.div`
    width: 82%;
    height: 82%;
    position: fixed;
    overflow-y: scroll;
    left: 15%;
    border: 2px solid ${LIGHT_GREY2};
    background-color: ${LIGHT_GREY1};
    font-family: Karla Bold;
    padding: 2rem;
    padding-bottom: 2rem;
`;

const SearchWrapper = styled.div`
    padding: 1rem 0rem 1rem 0rem 
`;

const Title = styled.div`
    color: ${DARK_BLUE};
    padding: 2rem 0rem 1rem 0.5rem;
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
    font-style: italic;
    font-family: Antique Olive Nord;
    display: flex;
    border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileEntry = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 0rem 2rem 0.5rem;
    font-size: 1.3rem;
    border-bottom: 1px solid ${LIGHT_GREY};
`;

const ProfileText = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 1.1rem;
`;

const ProfileIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`;


const UploadButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    padding-right: 1rem;
`;

const UploadButton = styled(Button)`
    margin-left: auto;
    height: 2.2rem;
    padding: 0rem 0.7rem 0rem 0.7rem;
`;

const AddUserButton = styled(Button)`
    margin-left: auto;
    height: 2.6rem;
    width: 100%;
    padding: 0rem 0.7rem 0rem 0.7rem;
`;

const ProfileIcon = styled.img`
  height: 3.5rem;
  width: 3.5rem;
  background-color: ${LIGHT_GREY};
  border-radius: 100%;
  margin-right: 0.5rem;
`;

export default ProfileTeam;
