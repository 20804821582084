import { Grid, Tooltip } from "@material-ui/core";
import styled from "styled-components";
import BasicStat from "../../components/game-page/basic-stat/basic-stat";
import RatioPieChart from "../../components/game-page/ratio-pie-chart/ratio-pie-chart";
import FourSectionPieChart from "../../components/home-page/four-section-pie-chart/four-section-pie-chart";
import SectionField from "../../components/game-page/section-field/section-field";
import { LIGHT_GREY1, LIGHT_GREY, WHITE, DARK_BLUE } from "../../assets/colors";
import SuccessRatio from "../../components/stats-base/success-ratio/success-ratio";
import ProgressBar from "../../components/stats-base/progress-bars/progress-bar-compare";
import { sportsAiGlobalStyles } from "../../shared/globalStyle";

const PlayerProfileAttacking = ({ player, sizeFilterButtons }) => {
  const classes = sportsAiGlobalStyles();
  if (player.position === "Goalkeeper") {
    return (
      <Wrapper>
        <FiltersWrapper>
          <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
        </FiltersWrapper>
        <SectionHeader>PASSES</SectionHeader>
        <Grid container spacing={2}>
          <Grid item xs={12}>
          <div
            style={{
              display: "grid",
              flexDirection: "row",
              gridTemplateColumns: "1.25fr 1.25fr 0.5fr",
              width: "100%",
              height: "100%",
              gap: "1rem",
            }}
          >
          <FourSectionPieChart
              title={"Pass Orientation (%)"}
              orientation={player.passOrientation}
              successRatio={[]}
            />
            <FourSectionPieChart
              title={"Pass Success (%)"}
              orientation={player.passOrientation}
              successRatio={player.passOrientationSuccess}
            />
            
            <SuccessRatio 
                title={"PASSES"} 
                successes={player.successfulPasses} 
                total={player.totalPasses}
              /></div>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                display: "grid",
                flexDirection: "row",
                gridTemplateColumns: "1fr 1fr 1fr 1fr",
                width: "100%",
                height: "100%",
                gap: "1rem",
              }}
            >
              <BasicStat
                title={"Key Passes"}
                value={player.keyPasses}
              />
              <BasicStat title={"Second Assists"} value={player.secondAssists} />
              <BasicStat title={"Assists"} value={player.assists} />
            <BasicStat title={"Crosses"} value={player.crosses} />
            </div>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={4}>
          </Grid>
          <Grid item xs={12}>
            <SummaryWrapper>
            <ProgressBar teamAStat={player.shortGoalkicks } teamBStat={player.longGoalkicks} title={"GOALKICKS (SHORT / LONG)"} scale={50} />
              </SummaryWrapper>
          </Grid>
        </Grid>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
      </FiltersWrapper>
      
      <SectionHeader>PASSES</SectionHeader>
      <Grid container spacing={2} style={{ marginTop: "1rem" }}>
        <Grid item xs={12}>
        <div
            style={{
              display: "grid",
              flexDirection: "row",
              gridTemplateColumns: "1.25fr 1.25fr 0.5fr",
              width: "100%",
              height: "100%",
              gap: "1rem",
            }}
          >
        <FourSectionPieChart
              title={"Pass Orientation (%)"}
              orientation={player.passOrientation}
              successRatio={[]}
            />
            <FourSectionPieChart
              title={"Pass Success (%)"}
              orientation={player.passOrientation}
              successRatio={player.passOrientationSuccess}
            />
          <SuccessRatio 
            title={"PASSES"} 
            successes={player.successfulPasses} 
            total={player.totalPasses}
          />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: "grid",
              flexDirection: "row",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              width: "100%",
              height: "100%",
              gap: "1rem",
            }}
          >
            <BasicStat
              title={"Key Passes"}
              value={player.keyPasses}
            />
            <BasicStat title={"Assists"} value={player.assists} />
            <BasicStat title={"2ND Assists"} value={player.secondAssists} />
            <BasicStat title={"Crosses"} value={player.crosses} />
          </div>
        </Grid>
          
      </Grid>
      <SectionHeader>Possession</SectionHeader>
      <SectionField
        title={"ball recoveries"}
        data={player.ballRecoveries}
        color={"blue"}
      />

<SectionHeader>Shots</SectionHeader>
      <Grid container spacing={2}>
        <Grid item container spacing={2} xs={6}>
          <Grid item xs={4}>
            <BasicStat title={"Shots"} value={player.shots} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"Goals"} value={player.goals} />
          </Grid>
          <Grid item xs={4}>
            <BasicStat title={"xG"} value={player.xg} />
          </Grid>
          <Grid item xs={6}>
            <BasicStat title={"Penalty shots"} value={player.penalties} />
          </Grid>
          <Grid item xs={6}>
            <BasicStat
              title={"Direct free-kicks"}
              value={player.directFreeKicks}
            />
          </Grid>
        </Grid>
        <Grid item xs={3}>
          <RatioPieChart
            title={"SHOTS"}
            ratio={player.shotPrecision / 100}
          />
        </Grid>
        <Grid item xs={3}>
          <SuccessRatio
            title={"DRIBBLES"}
            successes={player.dribblesSuccess}
            total={player.dribbles}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const SectionHeader = styled.div`
  padding-right: 10px;
  width: fit-content;
  background-color: ${LIGHT_GREY1};
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  font: 1rem 'Antique Olive Nord';
  color: ${DARK_BLUE}
`;

const SummaryWrapper = styled.div`
    background-color: ${WHITE};
    border-radius: 10px;
    border: 2px solid ${LIGHT_GREY};
    padding: 1rem 0.5rem;
    width:100%;
`;

export default PlayerProfileAttacking;
