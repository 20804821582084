import { useState } from "react";
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { IconButton } from "@material-ui/core";
import styled from "styled-components";
import { SportsSoccer } from "@material-ui/icons";
import Goal from "../../../models/goalScored";
import {
  GREY,
  LIGHT_BLUE
} from "../../../assets/colors";
import { EventPositionIcon } from "./event-position-icon";
import { EventPositionTooltip } from "./event-position-tooltip";

interface GoalInfo {
  id: number;
  x: number;
  y: number;
  color: string;
  dots: { x: number; y: number; type: string; color: string }[];
}

const initGrid = (width= 60, height= 50) => {
  let goalHashMap: any[][] = [];
  for (let i = 0; i < width; i++) {
    goalHashMap.push([]);
    for (let j = 0; j < height; j++) {
      goalHashMap[i].push([]);
    }
  }
  return goalHashMap;
}

type goaltypesType = "" | "s" | "g" | "k" | "p";

const FieldZoneEvent = ({ evntsPositions, indicatorIcons}) => {
  let isDataValid = true;
  let goals: Goal[][] = [];
  if (!evntsPositions) isDataValid = false;

  const graphDataAmount = 5
  const [goalTypes, setGoalTypes] = useState<(goaltypesType)[]>([]);
  const gridSize = { width: 60, height: 50 };
  const goalData: GoalInfo[] = [];
  if (isDataValid) {
    try {
      let goalHashMap: any[][] = initGrid();
      // assign goal color and add to goals array
      evntsPositions.forEach((gameGoals: Goal[]) => {
        const goalList: Goal[] = [];
        
        gameGoals.forEach((goal: Goal) => {
          if (goalTypes.includes(goal.type) || goalTypes.length === 0) {
            let color = "grey";
            for(const elemType of indicatorIcons){
              if (elemType.code === goal.type){
                color = elemType.color;
                break;
              }
            }
            if (goal.x !== null && goal.y !== null){
              goalList.push({
                x: goal.y < 60 ? (goal.y / 60) * 100 : 100,
                y: goal.x <= 50 ? 99.9 : (100 - goal.x) * 2 ,
                type: goal.type,
                color: color,
              });
            }
            
          }
        });
        goals.push(goalList);
      });

      for (
        let j = goals.length - 1;
        j >= 0 && goals.length - j <= graphDataAmount;
        j--
      ) {
        for (let i = 0; i < goals[j].length; i++) {
          const x = Math.floor((goals[j][i].x / 100) * gridSize.width);
          const y = Math.floor((goals[j][i].y / 100) * gridSize.height);
          goalHashMap[x][y].push({
            id: `${j}${i}`,
            x: x,
            y: y,
            type: goals[j][i].type,
            color: goals[j][i].color,
          });
        }
      }

      for (const row of goalHashMap) {
        for (const cluster of row) {
          for (const goal of cluster) {
            if (cluster.length > 0) {
              const xPos = (goal.x / gridSize.width) * 100;
              const yPos = (goal.y / gridSize.height) * 100;
              goalData.push({
                id: goal.id,
                x: xPos,
                y: yPos,
                color: goal.color,
                dots: cluster,
              });
            }
          }
        }
      }
    } catch (e) {
      
      isDataValid = false;
    }
  }

  const handleGoalTypeChange = (newType: goaltypesType) => {
    if (newType !== "" && !goalTypes.includes(newType)) {
      const copyArray = [...goalTypes];
      copyArray.push(newType);
      setGoalTypes(copyArray);
    } else if (goalTypes.includes(newType)) {
      const copyArray = [...goalTypes];
      copyArray.splice(copyArray.indexOf(newType), 1);
      setGoalTypes(copyArray);
    } else setGoalTypes([]);
  };
  
  return (
    <Wrapper>
      {!isDataValid ? <NoDataWrapper>No Data</NoDataWrapper> : <></>}
      <GraphWrapper>
        <ResponsiveContainer height={400}>
          <ScatterChart
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <XAxis hide type="number" dataKey="x" domain={[0, 100]} />
            <YAxis hide type="number" dataKey="y" domain={[0, 100]} />
            <Scatter
              data={goalData}
              shape={<EventPositionIcon />}
              isAnimationActive={false}
            />
            <Tooltip
              content={<EventPositionTooltip />}
              cursor={{ strokeDasharray: "3 3" }}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </GraphWrapper>
      <SoccerField id="soccer-field">
        <FieldCornerLine style={{ left: "-30px" }} />
        <FieldGoalLine />
        <FieldCornerLine style={{ right: "-30px" }} />
      </SoccerField>
      <Legend>
        {
          indicatorIcons.map((indicatorIcon) => {
            return (
                <>
                  <IconButton size="small" onClick={() => handleGoalTypeChange(indicatorIcon.code)}>
                    <SportsSoccer style={{ color: indicatorIcon.color }} />
                  </IconButton>                
                  <div style={{color: goalTypes.includes(indicatorIcon.code) ? indicatorIcon.color : "black" }}>
                    {indicatorIcon.title}
                    <p style={{ color: GREY, margin: "0 0 0 5px" }}>{`(${
                      indicatorIcon.value
                    })`}</p>
                  </div>  
                </>
                )
          })
        }
      </Legend>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 8px;
`;

const NoDataWrapper = styled.div`
  font-size: 1.6rem;
  color: ${GREY};
  width: 100%;
  display: flex;
  height: 0;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const GraphWrapper = styled.div`
  width: 90%;
  max-width: 1000px;
  position: relative;
  z-index: 1;
`;
const SoccerField = styled.div`
  border: 3px solid ${LIGHT_BLUE};
  max-width: 1000px;
  width: 90%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: end;
  background-color: transparent;
  margin-top: -400px;
  overflow: hidden;
`;
const FieldGoalLine = styled.div`
  border: inherit;
  border-bottom: none;
  width: 50%;
  height: 175px;
`;
const FieldCornerLine = styled.div`
  border: inherit;
  height: 60px;
  width: 60px;
  border-radius: 100%;
  position: relative;
  top: 30px;
`;
const Legend = styled.div`
  display: flex;
  margin-top: 20px;
  div {
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    margin: 0px 25px 0px 3px;
  }
`;

export default FieldZoneEvent;
