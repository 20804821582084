import styled from "styled-components";
import { changePage, Page } from "../../slices/navigationSlice";
import store, { useAppDispatch } from "../../app/store";
import {
  Button,
  InputAdornment,
  OutlinedInput,
  Select,
  MenuItem,
  Dialog,
  makeStyles,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward, Search } from "@material-ui/icons";
import { useEffect, useState } from "react";
import {
  getGame,
  selectGamesSummary,
  setCurrentGame,
} from "../../slices/gamesSlice";
import { GameSummary } from "../../models/game";
import { useSelector } from "react-redux";
import {
  DARK_BLUE,
  GREY,
  LIGHT_GREY1,
  LIGHT_GREY,
  LIGHT_GREY2,
  RED,
  GREEN,
  YELLOW,
  WHITE,
} from "../../assets/colors";
import { sendEvent } from "../../services/gAnalytics";
import TopBar from "../../components/navigation/topbar";
import { LoadingWrapper } from "../../components/navigation/loading";
import { CreateGame } from "./createGames";
import VideoUploader from "./videoUploader";
import { selectAccountType } from "../../slices/userSlice";
import { TEAM_ACCOUNT, TEAM_LEAGUE } from "../../models/constant/accountType";
import { searchBarStyles, sportsAiGlobalStyles } from "../../shared/globalStyle";

const sortOptions = ["Opponent", "Date", "Competition", "Place"];

const statsLabel = (game: GameSummary) => {
  if (!game.has_video && !game.video_link){
    return <>Upload Video</>
  }
  if (!game.isOperated) {
        return <>Processing </>
  }
  else {
    return <> Stats </>
  }
}



const GamesList = () => <LoadingWrapper comp={<GamesListBase/>}/>

const GamesListBase = () => {
  const classes = sportsAiGlobalStyles();
  
  const searchBarClass = searchBarStyles();
  const dispatch = useAppDispatch();
  const [games, setGames] = useState<GameSummary[]>(useSelector(selectGamesSummary));

  const [sortType, setSortType] = useState("Date");
  const [isReversed, setIsReversed] = useState(false);
  const [newGameCounter, setNewGameCounter] = useState(0);

  const [isCreateGameComp, setIsCreateGameComp] = useState<boolean>(false);
  const [isUploadVideoComp, setIsUploadVideoComp] = useState<boolean>(false);
  const [gameVideoId, setGameVideoId] = useState<number>(0);

  const accountType = useSelector(selectAccountType);
  const useStyles = makeStyles((theme) => ({
    outlinedButtonFlash: {
      color: WHITE,
      fontSize: "1rem",
      margin: "1rem",
      fontWeight: 600,
      width: "28%",
      borderRadius: "10px",
      border: "3px solid",
      borderColor: DARK_BLUE,
      backgroundColor: DARK_BLUE,
      marginBottom: "1rem",
      fontFamily: "Karla Bold",
      // Conditionally apply the flash animation if games.length is 0
      animation: games.length === 0 ? `$flash 1.5s infinite` : "none",
      "&:hover": {
      backgroundColor: DARK_BLUE,  // Change to a darker blue on hover
      color: "white",              // Change text color on hover
      //transition: "transform 0.3s ease, background-color 0.3s ease, color 0.3s ease", // Smooth transition
    },
    },
    // "@keyframes flash": {
    //   "0%": {
    //     opacity: 0.9,
    //   },
    //   "50%": {
    //     opacity: 1,
    //     transform: "scale(1.05)",
    //   },
    //   "100%": {
    //     opacity: 0.9,
    //     transform: "scale(1.0)",
    //   },
    // },
  }));
  const classe = useStyles();
  useEffect(() => {
    setGames(selectGamesSummary(store.getState()));
  }, [isCreateGameComp, isUploadVideoComp])

  const getScore = (game:  GameSummary) => {
    if (!game.isOperated)
      return (<div></div>)

    let color;
    if (game.teamScore > game.opponentScore) color = GREEN;
    else if (game.teamScore < game.opponentScore) color = RED;
    else color = GREY;

    if (game.place === "Home") {
      return (
        <div style={{ color: color }}>
          {`${game.teamScore} - ${game.opponentScore}`}
        </div>
      );
    } else {
      return (
        <div style={{ color: color }}>
          {`${game.opponentScore} - ${game.teamScore}`}
        </div>
      );
    }
  };

  const applySearchFilter = (searchFilter: string) => {
    searchFilter = searchFilter.toLowerCase();
    const filteredGames: GameSummary[] = [];

    for (const game of store.getState().gamesSlice.gamesSummary) {
      if (
        game.opponentName.toLowerCase().includes(searchFilter) ||
        game.date.toLowerCase().includes(searchFilter) ||
        game.competition.toLowerCase().includes(searchFilter) ||
        game.place.toLowerCase().includes(searchFilter) ||
        game.teamScore.toString().toLowerCase().includes(searchFilter) ||
        game.opponentScore.toString().toLowerCase().includes(searchFilter)
      ) {
        filteredGames.push(game);
      }
    }
    setGames(filteredGames);
  };

  const sortGames = (sortType: string) => {
    setIsReversed(false);

    const gamesCopy = [...games];
    switch (sortType) {
      case "Opponent":
        setGames(
          gamesCopy.sort((a, b) => (a.opponentName < b.opponentName ? -1 : 1))
        );
        break;
      case "Date":
        setGames(gamesCopy.sort((a, b) => (a.date < b.date ? -1 : 1)));
        break;
      case "Competition":
        setGames(
          gamesCopy.sort((a, b) => (a.competition < b.competition ? -1 : 1))
        );
        break;
      case "Place":
        setGames(gamesCopy.sort((a, b) => (a.place < b.place ? -1 : 1)));
        break;
    }
  };

  const reverseArray = () => {
    const gamesCopy = [...games];
    setGames(gamesCopy.reverse());
    setIsReversed(!isReversed);
  };

  const goToGameStats = (gameId: number) => {
    dispatch(setCurrentGame(undefined));
    dispatch(getGame(gameId));
    dispatch(changePage(Page.GameStat));
  };

  return (
    <Wrapper>
      <TopBar name={"Games"}/>
      <Dialog 
        open={isCreateGameComp} 
        onClose={() => setIsCreateGameComp(false)}
        maxWidth="md" // Set the desired max width
      >
        <CreateGame setIsCreateGameComp={setIsCreateGameComp} />
      </Dialog>
      <Dialog 
        open={isUploadVideoComp} 
        onClose={() => setIsUploadVideoComp(false)}
        maxWidth="md" // Set the desired max width
        >
        <VideoUploader gameId={gameVideoId} setIsStatModalOpen={setIsUploadVideoComp}/>
      </Dialog>
      {/* <OutlinedInput
        classes={searchBarClass}
        placeholder="Search a game..."
        onChange={(event) => applySearchFilter(event.target.value)}
        startAdornment={
          <InputAdornment position="start">
            <Search style={{ color: GREY }} />
          </InputAdornment>
        }
      /> */}
      <FiltersWrapper style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          {
            (accountType === TEAM_ACCOUNT || accountType === TEAM_LEAGUE) &&
            <Button 
              className={classe.outlinedButtonFlash}
              onClick={() => setIsCreateGameComp(true)} 
            >
              Add Game 
            </Button>
          }
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <SortTypeWrapper>
          <Select
            variant="outlined"
            style={{ height: "2rem", marginLeft: "5px", width: '9rem' }}
            value={sortType}
            onChange={(event) => {
              sendEvent("stats", "sort-Games",  event.target.value as string, 5);
              setSortType(event.target.value as string);
              sortGames(event.target.value as string);
            }}
          >
            {sortOptions.map((value) => {
              return (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              );
            })}
          </Select>
          
        </SortTypeWrapper>
        <SortTypeWrapper>
          <ArrowWrapper>
            {isReversed ? (
              <ArrowUpward
                className={classes.orderArrow}
                onClick={
                  () => reverseArray()}
              />
            ) : (
              <ArrowDownward
                className={classes.orderArrow}
                onClick={() => reverseArray()}
              />
            )}
          </ArrowWrapper>
        </SortTypeWrapper>
        </div>
      </FiltersWrapper>
      <Table>
        <TableHeader>
          <div style={{ textAlign: "left" }}>Opponent</div>
          <div></div>
          <div>Date</div>
          <div>Competition</div>
          <div>H/A</div>
          <div>Result</div>
          <div>Status</div>
        </TableHeader>
        {games.map((game, i) => {
          return game.opponentName &&
            game.date &&
            game.competition &&
            game.place ? (
            <GameInfo
              key={i}
              style={
                i % 2 === 0
                  ? { backgroundColor: "white" }
                  : { backgroundColor: LIGHT_GREY1 }
              }
              onClick={() => {
                if (!game.has_video && !game.video_link) {
                  setGameVideoId(game.id)
                  setIsUploadVideoComp(true)
                }
                else if (game.isOperated) {
                  sendEvent("stats", "select-game-Stats", game.place, i);
                  goToGameStats(game.id);
                }
              }}
            >
              <div style={{ textAlign: "left", textTransform: "uppercase" }}>{game.opponentName}</div>
              <div>
                <img 
                    src={game.opponentLogo} 
                    alt={`${game.opponentName} logo`} 
                    style={{ width: '40px', height: 'auto' }} // Adjust the size as needed
                />
              </div>
              <div>{game.date}</div>
              <div style={{textTransform: "uppercase" }}>{game.competition}</div>
              <div style={{textTransform: "uppercase" }}>{game.place}</div>
              <div>{getScore(game)}</div>
              <Button className={classes.transparantButton} style={{textTransform: "uppercase" }}>
                {statsLabel(game)}
              </Button>    
            </GameInfo>
          ) : (
            <></>
          );
        })}
      </Table>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 85%;
  height: 100%;
  position: fixed;
  overflow-y: scroll;
  left: 15%;
  border: 2px solid ${LIGHT_GREY2};
  font-family: Karla Bold;
`;

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem;
`;
const SortTypeWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  color: ${GREY};
  .MuiSelect-root {
    padding: 5px 10px;
  }
`;
const ArrowWrapper = styled.div`
  padding: 3px;
  border: 1px solid ${GREY};
  border-radius: 4px;
  background-color: white;
  margin-left: 10px;
`;
const Table = styled.div`
  width: 100%;
  margin: 40px 0px;
`;
const TableHeader = styled.div`
  display: grid;
  grid-template-columns: 20% 10% 10% 20% 15% 12.5% 12.5%;
  text-align: center;
  align-items: center;
  padding: 10px;
  border-bottom: 1.5px solid ${LIGHT_GREY};
  margin: 0 2rem;
  text-transform: uppercase;
`;
const GameInfo = styled.div`
  display: grid;
  grid-template-columns: 20% 10% 10% 20% 15% 12.5% 12.5%;
  text-align: center;
  align-items: center;
  color: ${DARK_BLUE};
  padding: 10px;
  border-radius: 8px;
  margin: 0 2rem;
  text-transform: uppercase;
  > div {
    text-transform: capitalize;
  }
  &:hover {
    cursor: pointer;
  }
`;

export default GamesList;
