import { Grid } from "@material-ui/core";
import styled from "styled-components";
import BasicStat from "../../components/game-page/basic-stat/basic-stat";
import SectionField from "../../components/game-page/section-field/section-field";
import SuccessRatio from "../../components/stats-base/success-ratio/success-ratio";
import { DARK_BLUE, LIGHT_GREY, LIGHT_GREY1 } from "../../assets/colors";
import FacedShotsPosition from "../../components/home-page/zone-event-position/zone-saves-position";

const PlayerProfileDefending = ({ player, sizeFilterButtons }) => {
  if (player.position === "Goalkeeper") {
    return (
      <Wrapper>
        <FiltersWrapper>
          <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
        </FiltersWrapper>
        <HR></HR>
        <SectionHeader>FACED SHOTS</SectionHeader>
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          <Grid item xs={3}>
            <div
              style={{
                display: "grid",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <BasicStat title={"SAVES"} value={player.shotBlocks} />
              <BasicStat title={"GOALS AGAINST"} value={player.concededGoals} />
            </div>
          </Grid>
          <Grid item xs={6}>
            <SuccessRatio
              title={"SAVES %"}
              successes={player.shotBlocks}
              total={player.shotBlocks + player.concededGoals}
            />
          </Grid>
          <Grid item xs={3}>
            <div
              style={{ display: "grid", flexDirection: "column", gap: "1rem" }}
            >
              <BasicStat title={"Most Goals Conceded"} value={player.mostGoalsConceded} />
              <BasicStat title={"Most Saves Made"} value={player.mostSavesMade} />
            </div>
          </Grid>
        </Grid>
        <div style={{marginTop: "1rem"}}>
          <FacedShotsPosition/>
        </div>
        <HR></HR>
        <SectionHeader>Challenges</SectionHeader>
        <Grid container spacing={2} style={{ marginTop: "2rem" }}>
          <Grid item xs={3}>
            <div
              style={{
                display: "grid",
                flexDirection: "column",
                gap: "1rem",
              }}
            >
              <BasicStat title={"Challenges"} value={player.totalChallenges} />
              <BasicStat title={"Fouls"} value={player.fouls} />
            </div>
          </Grid>
          <Grid item xs={3}>
            <SuccessRatio
              title={"TACKLES"}
              successes={player.successfulTackles}
              total={player.totalTackles}
            />
          </Grid>
          <Grid item xs={3}>
            <SuccessRatio
              title={"CHALLENGES"}
              successes={player.successfulChallenges}
              total={player.totalChallenges}
            />
          </Grid>
          <Grid item xs={3}>
            <div
              style={{ display: "grid", flexDirection: "column", gap: "1rem" }}
            >
              <BasicStat title={"Yellow Cards"} value={player.yellowCards} />
              <BasicStat title={"Red Cards"} value={player.redCards} />
            </div>
          </Grid>
        </Grid>
      </Wrapper>
    );    
  }
  return (
    <Wrapper>
      <FiltersWrapper>
        <SizeFilterWrapper>{sizeFilterButtons}</SizeFilterWrapper>
      </FiltersWrapper>
      <Grid container spacing={2} style={{ marginTop: "2rem" }}>
        <Grid item xs={3}>
          <div
            style={{
              display: "grid",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <BasicStat title={"Challenges"} value={player.totalChallenges} />
            <BasicStat title={"Fouls"} value={player.fouls} />
          </div>
        </Grid>
        <Grid item xs={3}>
          <SuccessRatio
            title={"TACKLES"}
            successes={player.successfulTackles}
            total={player.totalTackles}
          />
        </Grid>
        <Grid item xs={3}>
          <SuccessRatio
            title={"CHALLENGES"}
            successes={player.successfulChallenges}
            total={player.totalChallenges}
          />
        </Grid>
        <Grid item xs={3}>
          <div
            style={{ display: "grid", flexDirection: "column", gap: "1rem" }}
          >
            <BasicStat title={"Yellow Cards"} value={player.yellowCards} />
            <BasicStat title={"Red Cards"} value={player.redCards} />
          </div>
        </Grid>
        <Grid item xs={6}>
            <BasicStat title={"Shot Blocked"} value={player.shotBlocks} />
        </Grid>
        <Grid item xs={6}>
          <BasicStat title={"Clearances"} value={player.clearances} />
        </Grid> 
      </Grid>
      
      <div style={{ marginTop: "2rem" }}>
        <SectionField
          title={"ball loss reactions"}
          data={player.ballLossReactions}
          color={"red_blue"}
        />
      </div>
      <div style={{ marginTop: "2rem" }}>
        <SectionField
          title={"ball loss map"}
          data={player.ballLosses}
          color={"red"}
        />
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 2rem;
`;
const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SizeFilterWrapper = styled.div`
  display: flex;
  width: fit-content;
  padding: 0.7rem;
  background-color: white;
  border-radius: 8px;
`;
const SectionHeader = styled.div`
  padding-right: 10px;
  width: fit-content;
  background-color: ${LIGHT_GREY1};
  margin-bottom: 1rem;
  text-transform: uppercase;
  font: 1rem 'Antique Olive Nord';
  color: ${DARK_BLUE}
`;

const HR = styled.div`
  height: 2px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: -0.7rem;
  background-color: ${LIGHT_GREY};
`;
export default PlayerProfileDefending;
